<script setup lang="ts">
const props = defineProps({
  text: {
    type: String as PropType<string>,
    default: "",
  },
})

const quote = computed(() => {
  const formattedText = props.text.replace("»", '"').replace("«", '"')

  const quoteStart = formattedText.indexOf('"')
  const quoteEnd = formattedText.lastIndexOf('"')

  const text =
    quoteEnd === -1
      ? formattedText
      : formattedText.substring(quoteStart + 1, quoteEnd)

  const cite =
    quoteEnd === -1
      ? null
      : formattedText.substring(quoteEnd + 1, formattedText.length)

  return { text, cite }
})
</script>

<template>
  <section
    v-if="quote.text"
    class="flex w-full justify-center bg-[--theme-lighter] px-12 py-10 text-center font-national2condensed text-[--theme-dark] md:px-32 md:py-20"
  >
    <figure>
      <blockquote
        class="relative max-w-[600px] text-left text-marketing-mobile-quote-quote md:text-marketing-desktop-quote-quote"
      >
        <div class="h-6 text-[64px] italic md:h-10 md:!text-[80px]">
          “
        </div>
        <div class="italic text-pretty" v-text="quote.text" />
      </blockquote>
      <figcaption v-if="quote.cite" class="pt-4 text-left md:pt-8">
        <cite
          class="text-marketing-mobile-p1-p1-regular-s not-italic md:text-marketing-desktop-p1-p1-regular-s"
          v-text="quote.cite"
        />
      </figcaption>
    </figure>
  </section>
</template>
