<script setup lang="ts">
import { useBackgroundColor } from "~/composables/useBackgroundColor"
import { useRouter } from "vue-router"

const route = useRoute<"product">()
const router = useRouter()
const productContent = useProductContentStore()
const products = useProductVariantsStore()

const backgroundColor = useBackgroundColor()
backgroundColor.value = "var(--theme-darkest)"

// Watch for the query parameter and navigate to first free subject
watchEffect(async () => {
  const toFreeChapter = route.query["til-gratis-kapittel"]
  const subjectsLoaded =
    !productContent.hydrationInProgress &&
    productContent.subjects.length > 0

  if (toFreeChapter && subjectsLoaded) {
    const hasFreeSubject = productContent.subjects.find(
      subject => subject.isFree,
    )

    if (hasFreeSubject?.frontendHref) {
      await router.replace(hasFreeSubject.frontendHref)
    } else {
      await router.replace({
        path: route.path,
        query: { ...route.query, "til-gratis-kapittel": undefined },
      })
    }
  }
})
</script>

<template>
  <u-feature-flag
    class="fixed top-0 left-0 z-50 p-4 text-gray-400 w-fit"
  >
    <UButtonNew to="/" variant="quite" size="sm" theme="white"
      >kunne.no</UButtonNew
    >
  </u-feature-flag>

  <div
    class="flex w-full items-center justify-center bg-[--theme-darkest]"
  >
    <div
      class="flex flex-col max-w-[1465px] items-center justify-center w-full px-9 lg:px-20 md:flex-row md:items-start md:gap-16"
    >
      <u-side-nav
        :data="productContent.subjectNav"
        :product="products.activeVariant"
        :folders="productContent.folders"
        :licence="products.activeVariant?.userHasLicence"
        page-type="product"
      />
      <main class="w-full">
        <UMyProfileButton
          :path="[route.params.product, route.params.variant]"
        />
        <router-view />
      </main>
      <UMarketingBanner />
    </div>
  </div>
</template>
