<script setup lang="ts">
import { ContentProductPageBlocks } from "~/models/Content/ContentProductPageBlocks"
import UProductPageBlockQuote from "~/components/Marketing/UProductPageQuote.vue"
import { ContentProductPageMediaTypes } from "~/models/Content/ContentProductPageMediaTypes"
import {
  isProductPageBlock,
  isProductPageMediaTypes,
} from "~/api/getProductPageList"
import UProductPageBlock from "~/components/Marketing/UProductPageBlock.vue"
import USellingPoints from "~/components/Marketing/USellingPoints.vue"

const data = useProductVariantsStore()
const activeProduct = computed(() => data.activeProduct)

type ProductPageChildren = (
  | ContentProductPageBlocks
  | ContentProductPageMediaTypes
)[]

const productBlocks = computed<ProductPageChildren | undefined>(
  () => {
    if (data.product && data.marketingData) {
      return data.marketingData[data.product]?.blocks
    } else {
      return undefined
    }
  },
)

const productUsp = computed(() => {
  if (data.product && data.marketingData) {
    return data.marketingData[data.product]?.uspList
  } else {
    return undefined
  }
})

const heroSectionMounted = ref(false)

const style = getComputedStyle(document.body)
style.getPropertyValue("--theme-loaded")

const containerClass =
  "flex flex-col items-center justify-center px-8 text-xl lg:px-40"
</script>

<template>
  <div class="flex flex-col gap-14 text-[--theme-darkest] lg:gap-24">
    <div :class="containerClass">
      <UProductPageHero @mounted="heroSectionMounted = true" />
    </div>

    <section v-if="productUsp" class="lg:px-40 px-12 self-center">
      <h3 class="sr-only">
        Hvorfor kjøpe Kunne {{ activeProduct?.productTitle }}?
      </h3>
      <ul
        class="flex w-fit flex-col items-stretch justify-center gap-8 lg:flex-row list-none"
      >
        <UProductPageUSP
          v-for="usp in productUsp"
          :key="usp"
          :usp="usp"
        />
      </ul>
    </section>
    <UProductPageBlockQuote
      v-if="data.product"
      :text="data?.marketingData?.[data.product]?.quote"
    />
    <UProductPagePensum />
    <div
      v-if="heroSectionMounted && productBlocks"
      class="gap-14 lg:gap-24"
      :class="containerClass"
    >
      <template v-for="productBlock in productBlocks">
        <UProductPageBlock
          v-if="isProductPageBlock(productBlock)"
          :key="productBlock.contentId"
          :product-block-data="productBlock"
        />
        <USellingPoints
          v-if="isProductPageMediaTypes(productBlock)"
          :key="productBlock.contentId"
          :selling-points="productBlock.productMediaTypes"
          :bg-color="'bg-[--theme-dark]'"
          :text-color="'text-[--theme-lightest]'"
        />
      </template>
    </div>
    <UProductPageHero
      position="bottom"
      class="p-12 mt-12 md:mt-24 md:py-16 md:px-24"
    />
  </div>
</template>

<route lang="yaml">
name: productPage
meta:
  layout: productPage
</route>
