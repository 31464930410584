<script setup lang="ts">
const data = useMarketingStore()
import { PageData } from "~/types/pages"
import { sanitizeForUrl } from "~/utilities/urls"

const isExternalLink = (href: string) => {
  return href.startsWith("http") || href.startsWith("#")
}

const links = computed(() => {
  const dynamicLinks = (data.pagesAndMarketingItems?.pages ?? []).map(
    (page: PageData) => {
      return {
        title: page.title,
        href: `/om/${sanitizeForUrl(page.title)}`,
        query: { side: page.id },
        customClass: "",
      }
    },
  )

  return [
    ...dynamicLinks,
    {
      title: "Studentliv",
      href: `/studentliv/eksamenstips`,
      query: {},
      customClass: "",
    },
    {
      title: "Universitetsforlaget",
      href: "https://www.uf.no",
      query: {},
      customClass: "",
    },
    {
      title: "Administrer Informasjonskapsler",
      href: "#",
      query: {},
      customClass: "cky-banner-element",
    },
  ]
})
</script>

<template>
  <footer
    class="flex h-full w-screen flex-col items-center justify-center overflow-hidden bg-[--theme-darker] py-20 md:h-80 md:py-0"
  >
    <div
      class="flex w-[90vw] max-w-[100em] flex-col items-center justify-center sm:justify-between md:w-[80vw] md:flex-row"
    >
      <div class="flex w-full flex-col justify-center gap-4">
        <ULogo aschehoug header-level="h2" :dark="false" />
        <div class="pb-10 text-lighter opacity-75">
          H Aschehoug & Co W Nygaard AS<br />Org. nr 910 292 005<br />Sehesteds
          gate 3, 0164 Oslo
        </div>
      </div>
      <div
        id="links"
        class="flex w-full items-end justify-end text-white"
      >
        <ul
          class="flex h-full w-full flex-col flex-wrap items-start justify-center gap-3 sm:justify-start lg:h-36 lg:w-fit"
        >
          <li
            v-for="link in links"
            :key="link.title"
            class="md:ml-5"
            :class="link?.customClass"
          >
            <RouterLink
              v-if="!isExternalLink(link.href)"
              :to="{ path: link.href, query: link.query }"
            >
              {{ link.title }}
            </RouterLink>
            <a v-else :href="link.href">
              {{ link.title }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<style lang="css">
#links a,
#links .router-link-active {
  @apply font-national2 underline decoration-2 underline-offset-8 hover:text-u-contrast hover:decoration-u-contrast;
}
</style>
