<script setup lang="ts">
import {
  ProductInformation,
  ProductsList,
} from "~/models/Content/ContentProducts"
import UContactCustomerService from "~/components/CustomerService/UContactCustomerService.vue"

const props = defineProps({
  product: {
    type: Object as PropType<ProductsList>,
    required: true,
  },
})

const showVariantModal = inject("showVariantModal") as Ref<boolean>
const emit = defineEmits(["close"])

function getVariantPath(variant: ProductInformation) {
  const path = `/produkter/${props.product.productKey}/${variant.variantKey}`

  const query = !variant.userHasLicence
    ? { "til-gratis-kapittel": "true" }
    : undefined
  return { path, query }
}
</script>

<template>
  <div>
    <u-modal v-model="showVariantModal" @close="emit('close')">
      <template #header>
        <h1
          class="bold font-national2condensed text-h-1-national-2-c text-dark-blue-80"
        >
          Hvor studerer du?
        </h1>
      </template>
      <div class="flex flex-col gap-2">
        <UButtonNew
          v-for="variant in product?.variants.filter(
            v => v.isReleased,
          )"
          :key="variant.shortVariantLabel"
          class="mb-2"
          size="md"
          theme="cta"
          :to="getVariantPath(variant)"
        >
          {{ variant.curriculumFor.replaceAll(" og ", " eller ") }}
        </UButtonNew>
      </div>
      Finner du ikke ditt studiested?
      <UContactCustomerService />.
    </u-modal>
  </div>
</template>
