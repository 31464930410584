<script setup lang="ts">
import { useCheckout } from "~/composables/useCheckout"
import { useLicenceStore } from "~/store/licenceStore"
import { ProductInformation } from "~/models/Content/ContentProducts"
import * as Sentry from "@sentry/vue"
import { useEcommerceCatalogue } from "~/composables/useEcommerceCatalogue"

const props = defineProps({
  selectedProduct: {
    type: Object as PropType<ProductInformation>,
    required: false,
  },
  productKey: {
    type: String,
    required: true,
  },
  step: {
    type: String,
    required: true,
  },
  activeStep: {
    type: String,
    required: true,
  },
})

const checkoutShow = defineModel<boolean>("checkoutShow")
const checkoutError = defineModel<boolean>("checkoutError")

const licenceStore = useLicenceStore()
await licenceStore.setLicenceData()
const hasLicence = computed(() =>
  licenceStore.hasLicenceByEan(props.selectedProduct?.ean ?? ""),
)

const checkout = useCheckout()
const auth = useAuth()
const loggedIn = computed(() => auth.isAuthorized.value)

function submit() {
  checkoutShow.value = true
  checkoutError.value = false

  if (props.selectedProduct?.ean) {
    checkout
      .submit(
        { iFrameContainerId: "vipps-checkout-frame-container" },
        `produkter/${props.productKey}/${props.selectedProduct.variantKey}`,
        props.selectedProduct.ean,
      )
      .catch(() => {
        Sentry.captureException(
          `Unable to submit payment request to via checkout API to Vipps for product [${props.productKey}] with variant [${props.selectedProduct?.shortVariantLabel}]`,
        )
        checkoutError.value = true
      })
  } else {
    Sentry.captureException(
      `EAN is missing in payment step of product [${props.productKey}] with variant [${props.selectedProduct?.shortVariantLabel}]`,
    )
    checkoutError.value = true
  }
}

const { productPriceFilteredByEAN } = useEcommerceCatalogue()

const productPrice = computed(() => {
  const ean = props.selectedProduct?.ean

  if (ean) {
    return productPriceFilteredByEAN(props.selectedProduct.ean)
  } else {
    Sentry.captureException(
      `Unable to set product price due to missing EAN for [${props.selectedProduct?.name}] with variant [${props.selectedProduct?.shortVariantLabel}]`,
    )

    return undefined
  }
})

const isPaid = computed(() => !!productPrice.value?.actual)
</script>

<template>
  <UStep v-if="selectedProduct" :is-active-step="activeStep === step">
    <!--  TODO: Handle product variant not found  -->
    <template #step>{{ step }}</template>
    <template #title>
      <template v-if="hasLicence && selectedProduct">
        Du har allerede tilgang til Kunne {{ selectedProduct.name }}
      </template>
      <template v-else-if="!hasLicence">
        {{ isPaid ? "Betaling" : "Prøv produktet" }}
      </template>
    </template>
    <template #content>
      <template v-if="!loggedIn" />
      <template v-else-if="hasLicence">
        <UButtonNew
          :to="`/produkter/${
            productKey + '/' + selectedProduct?.variantKey
          }`"
          pill
          theme="cta"
          variant="filled"
        >
          Gå til Kunne {{ selectedProduct?.name ?? "" }}
        </UButtonNew>
      </template>
      <template v-else>
        <form class="flex text-xl" @submit.prevent="submit()">
          <section class="flex flex-col gap-12">
            <div v-if="isPaid" class="font-national2">
              <label class="flex items-baseline gap-3">
                <input
                  v-model="checkout.termsAccepted.value"
                  type="checkbox"
                  required
                />
                <span>
                  Jeg samtykker til
                  <a
                    href="/om/kjoepsvilkaar?side=3300"
                    style="font: inherit; text-decoration: underline"
                    >kjøpsvilkårene</a
                  >
                </span>
              </label>
            </div>

            <UCheckoutCard
              v-if="selectedProduct"
              :price="productPrice"
              :selected-product="selectedProduct"
              :show-payment="true"
            />
          </section>
        </form>
      </template>
    </template>
  </UStep>
  <UStep v-else :is-active-step="activeStep === step">
    <template #step>{{ step }}</template>
    <template #title>Betaling</template>
  </UStep>
</template>

<style scoped lang="postcss"></style>
