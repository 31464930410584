import type { BaseItem } from "~/models/Content/BaseItem"
import type { Criterion } from "~/models/Search"
import { ContentType } from "~/models/Content/ContentType"
import { fetchResults } from "~/composables/useSearch"
import { mapContents } from "~/mapping/mapToContentTypes"
import * as Sentry from "@sentry/vue"
import type { SearchResult } from "~/models/Content/SearchResult"

export async function getContent<T extends BaseItem>(
  criterions: Criterion,
  limit: number = 100,
  offset: number = 0,
  fields: string[] = [],
  options: { includeChildren?: boolean; maxDepth?: number } = {},
): Promise<T[]> {
  try {
    const results = await fetchResults(
      { fields },
      criterions,
      limit,
      offset,
    )
    const mappedResults = mapRelationsToResults<T>(results)
    if (options.includeChildren) {
      const resultWithChildren = await attachChildren(
        mappedResults,
        fields,
        options.maxDepth,
      )
      if (
        criterions.contentTypeCriterion?.includes(ContentType.Article)
      ) {
        //debugger
      }
      return resultWithChildren
    }

    return mappedResults ?? []
  } catch (error) {
    console.error(
      "Rest api request or mapping teh results failed:",
      error,
    )

    Sentry.captureException(error, {
      extra: {
        criterions,
        limit,
        offset,
        fields,
      },
    })
    return []
  }
}

async function attachChildren<T extends BaseItem>(
  items: T[],
  fields: string[] = [],
  maxDepth?: number,
): Promise<T[]> {
  if (items.length === 0) return items
  if (maxDepth === 0) return items

  // Get all locationIds in one go
  const locationIds = items.map(item => item.locationId)

  // Fetch all children in a single query
  const allChildren = await getContent<BaseItem>(
    {
      parentLocationIdCriterion: locationIds,
      sortField: "priority",
      sortOrder: "asc",
    },
    100,
    0,
    fields,
    {
      includeChildren: true,
      maxDepth: !maxDepth ? 0 : maxDepth - 1,
    },
  )

  // Create a map for quick lookup
  const childrenMap = allChildren.reduce(
    (acc, child) => {
      if (child.parentLocationId) {
        if (!acc[child.parentLocationId]) {
          acc[child.parentLocationId] = []
        }
        acc[child.parentLocationId].push(child)
      }
      return acc
    },
    {} as Record<number, BaseItem[]>,
  )

  // Assign children to their parents
  return items.map(item => ({
    ...item,
    children: childrenMap[item.locationId] ?? [],
  }))
}

function mapRelationsToResults<T extends BaseItem>(
  results: SearchResult,
): T[] {
  const mappedResults = mapContents<T>(results)

  if (results.relations) {
    const mappedRelations = mapRelationsToResults(results.relations)

    mappedResults.forEach(item => {
      item.relatedItems = mappedRelations.filter(relation =>
        item.relationsIds.includes(relation.contentId),
      )
    })
  }

  return mappedResults
}
