import { Subject } from "~/types/subject"

// Function to find the next valid subject with children
export const findNextValidSubject = (
  subjects: Subject[],
  startIndex: number,
) => {
  for (let i = startIndex; i < subjects.length; i++) {
    const subject = subjects[i]
    if (!subject.restricted && subject.totalChildren > 0) {
      return subject
    }
  }
  return undefined
}

// Function to find the previous valid subject with children
export const findPreviousValidSubject = (
  subjects: Subject[],
  startIndex: number,
) => {
  for (let i = startIndex; i >= 0; i--) {
    const subject = subjects[i]
    if (!subject.restricted && subject.totalChildren > 0) {
      return subject
    }
  }
  return undefined
}
