import type { Article } from "~/types/article"

import { ContentType } from "~/models/Content/ContentType"
import { formatData } from "~/mapping/article/format-data"
import { getContent } from "~/api/getContent"

/**
 * Get the articles from Ibexa
 * @param locationId
 * @returns the articles
 */
export default async (locationId: number): Promise<Article[]> => {
  try {
    const results = await getContent<Article>(
      {
        contentTypeCriterion: [ContentType.Article],
        parentLocationIdCriterion: [locationId],
        sortField: "priority",
        sortOrder: "asc",
      },
      undefined,
      undefined,
      undefined,
      { includeChildren: true, maxDepth: 3 },
    )

    return results.map(formatData)
  } catch (error) {
    console.error("Error fetching articles:", error)
    return []
  }
}

export async function getArticleByLocationId(
  id: number,
): Promise<Article | null> {
  const results = await getContent<Article>({
    contentTypeCriterion: [ContentType.Article],
    locationIdCriterion: [id],
  })

  if (!results?.length) return null

  return formatData(results[0])
}
