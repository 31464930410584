<script setup lang="ts" name="SearchPage">
import type { Directive } from "vue"
import type { LocationQuery } from "vue-router"
import { articleTemplates } from "~/utilities/articles"

const route = useRoute<"search">()
const router = useRouter()
const search = useContentSearch()
const data = useProductContentStore()
const returnTo = ref(
  firstString(route.query.from) ||
    "/produkter/" + route.params.product + "/" + route.params.variant,
)
const showFilters = ref(false)
const showPreviews = ref(true)
const dialogEl = ref<HTMLDialogElement | null>(null)
const loadMoreObserverListEl = ref<HTMLDivElement | null>(null)
const loadMoreObserverPreviewEl = ref<HTMLDivElement | null>(null)

const activeCursorArticle = computed(() => {
  return search.results.data.find(
    ({ id }) => id.toString() === route.query.cursor,
  )
})

const activeCursorIndex = computed(
  () =>
    search.results.data.findIndex(
      ({ id }) => id.toString() === firstString(route.query.cursor),
    ) ?? 0,
)

const nextCursor = computed(() => {
  return (
    search.results.data[activeCursorIndex.value + 1]?.id.toString() ??
    null
  )
})

const prevCursor = computed(() => {
  return (
    search.results.data[activeCursorIndex.value - 1]?.id.toString() ??
    null
  )
})

const nextPageAmount = computed(() => {
  return Math.min(
    10,
    search.results.count.filteredTotal - search.results.data.length,
  )
})

watch(activeCursorArticle, val => {
  const el = dialogEl.value

  if (el == null) return
  if (val && !el.open) el.showModal()
  if (!val && el.open) el.close()
})

// Remove "&from="
if (route.query.from) {
  router.replace({ query: { q: route.query.q } })
}

function firstString(value: LocationQuery[1]) {
  return (Array.isArray(value) ? value[0] : value) ?? ""
}

search.query.value = firstString(route.query.q)
if (route.query.template)
  search.templateIdFilter.value.push(
    ...(Array.isArray(route.query.template)
      ? route.query.template.map(n => Number(n))
      : [Number(route.query.template)]),
  )
search.submit()

watch(
  () => route.query.q,
  q => {
    search.query.value = firstString(q)
    search.submit()
  },
)

watch(search.templateIdFilter, template => {
  router.replace({ query: { ...route.query, template } })
  search.submit()
})

watch(search.locationIdFilter, () => search.submit())

const loadMoreObserver = new IntersectionObserver(entries => {
  if (
    search.results.data.length >= search.results.count.filteredTotal
  )
    return
  if (search.loading.value > 0) return
  if (!entries.some(entry => entry.isIntersecting)) return

  search.next()
})

watch([loadMoreObserverListEl, loadMoreObserverPreviewEl], els => {
  els.forEach(el => {
    if (el == null) return
    loadMoreObserver.observe(el)
  })
})

const highlights = new Map()

const vHighlight: Directive = {
  mounted(el, { value, arg }) {
    if (!("highlights" in CSS)) return

    const observer = new MutationObserver(mutations => {
      const query = value.toLowerCase()

      if (value.length <= 0) return
      if (!highlights.has(arg))
        highlights.set(arg, { query, ranges: [] })

      const highlight = highlights.get(arg)

      if (highlight.query !== query) {
        highlight.query = query
        highlight.ranges = []
      }

      const { ranges } = highlight
      const walker = document.createTreeWalker(
        el,
        NodeFilter.SHOW_TEXT,
      )
      let node: Node | null = walker.nextNode()

      CSS.highlights.clear()

      while (node) {
        const text = node.textContent?.toLowerCase().trim()
        let pos = 0

        if (text == null) break

        while (pos < text.length) {
          const index = text.indexOf(query, pos)

          if (index < 0) break

          const range = new Range()
          range.setStart(node, index)
          range.setEnd(node, index + query.length)

          ranges.push(range)

          pos = index + query.length
        }

        node = walker.nextNode()
      }

      CSS.highlights.set(arg, new Highlight(...ranges.flat()))
    })

    observer.observe(el, { subtree: true, childList: true })
  },
}

const activeMediaTypeFilters = computed(() => {
  const filters = search.templateIdFilter.value
  const templateNames = filters.map(templateId => {
    const template = [...articleTemplates.values()].find(
      ({ ibexaId }) => ibexaId === templateId,
    )
    return template ? template.name : templateId
  })
  return filters.length > 0
    ? `innholdstype: ${templateNames.join(", ")}`
    : ""
})

const activeChapterFilters = computed(() => {
  const locations = search.locationIdFilter.value
  if (locations.length === 0) {
    return ""
  }

  const locationTitles = locations
    .map(locationId => {
      const location = data.subjects.find(
        ({ id }) => id === locationId,
      )
      return location ? location.title : locationId
    })
    .join(", ")

  return `kapittel: ${locationTitles}`
})
</script>

<template>
  <div
    class="grid gap-[var(--app-padding)]"
    :class="{
      'md:grid-cols-[342px,minmax(0,1fr)] lg:grid-cols-[456px,minmax(0,1fr)]':
        showPreviews,
    }"
  >
    <div
      class="sticky top-[var(--app-header-height)] grid h-fit grid-rows-[repeat(2,max-content),minmax(0,1fr),max-content] pb-5"
    >
      <div
        class="grid items-center gap-3 <md:grid-cols-[max-content,1fr]"
      >
        <router-link
          v-wave
          :to="`${returnTo}`"
          class="grid p-4 border-2 border-current rounded-full hover:border-u-contrast md:fixed md:left-5 md:top-5"
          aria-label="Lukk søk"
        >
          <u-icon name="close" class="stroke-current" size="xxs" />
        </router-link>

        <u-search-bar />
      </div>

      <div
        class="flex flex-wrap gap-3 overflow-auto border-b-2 border-white pb-3 md:pt-12 <md:pt-5"
      >
        <span
          class="grid content-center flex-none italic font-bold font-national2"
        >
          {{ search.results.count.filteredTotal }} treff
        </span>

        <button
          v-wave
          class="px-4 py-1 border-2 border-current rounded-full hover:border-u-contrast"
          aria-label="Endre visning"
          @click="showPreviews = !showPreviews"
        >
          <u-icon size="sm" name="list" />
        </button>

        <button
          v-wave
          class="px-4 py-1 border-2 border-white rounded-full font-national2 hover:border-u-contrast"
          :class="{ 'bg-white text-portal-indigo-900': showFilters }"
          :aria-pressed="showFilters"
          @click="showFilters = !showFilters"
        >
          Filtrer
        </button>

        <button
          v-for="locationId in search.locationIdFilter.value"
          :key="locationId"
          v-wave
          class="grid max-w-full grid-cols-[max-content,1fr] items-center gap-1 rounded-full border-2 border-red-20 bg-red-20 py-1 pl-1 pr-4 text-portal-indigo-900 hover:border-u-contrast"
          @click="
            search.locationIdFilter.value =
              search.locationIdFilter.value.filter(
                value => value !== locationId,
              )
          "
        >
          <u-icon
            size="xxs"
            class="p-2 stroke-current"
            name="close"
          />
          <div
            class="overflow-hidden overflow-ellipsis whitespace-nowrap"
          >
            <span class="sr-only">
              Fjern filtrering på kapittel
            </span>
            {{
              data.subjects.find(({ id }) => id === locationId)
                ?.title ?? locationId
            }}
          </div>
        </button>

        <button
          v-for="templateId in search.templateIdFilter.value"
          :key="templateId"
          v-wave
          class="grid grid-cols-[max-content,1fr] items-center gap-1 rounded-full border-2 border-red-20 bg-red-20 py-1 pl-1 pr-4 text-portal-indigo-900 hover:border-u-contrast"
          @click="
            search.templateIdFilter.value =
              search.templateIdFilter.value.filter(
                value => value !== templateId,
              )
          "
        >
          <u-icon
            size="xxs"
            class="p-2 stroke-current"
            name="close"
          />
          <span class="sr-only"> Fjern filtrering på medietype </span>
          {{
            [...articleTemplates.values()].find(
              ({ ibexaId }) => ibexaId === templateId,
            )?.name ?? templateId
          }}
          <div class="sr-only">filter</div>
        </button>
      </div>
      <div
        v-if="showFilters"
        class="overflow-auto scrollbar-hide no-scrollbar h-fit"
      >
        <details>
          <summary
            v-wave
            class="grid cursor-default grid-cols-[max-content,1fr] items-center gap-5 border-b border-white border-opacity-20 p-5 font-national2 text-lg font-bold italic"
          >
            <u-icon name="chevron-down" class="scale-[0.833]" />
            <span class="sr-only"> Filtrér på </span> Kapittel
          </summary>
          <ul>
            <li
              v-for="{ id, title } in data.subjects.filter(
                ({ id }) =>
                  search.results.count.locations.get(id) !== 0,
              )"
              :key="id"
            >
              <label
                v-wave
                class="grid w-full grid-cols-[max-content,1fr,max-content] items-center gap-5 border-b border-white border-opacity-20 p-5 text-left"
              >
                <input
                  v-model="search.locationIdFilter.value"
                  type="checkbox"
                  class="input-checkbox"
                  name="filter-topic"
                  :value="id"
                />
                <span>{{ title }}</span>
                <span>
                  {{ search.results.count.locations.get(id) }}
                </span>
              </label>
            </li>
          </ul>
        </details>

        <details open>
          <summary
            v-wave
            class="grid cursor-default grid-cols-[max-content,1fr] items-center gap-5 border-b border-white border-opacity-20 p-5 font-national2 text-lg font-bold italic"
          >
            <u-icon name="chevron-down" class="scale-[0.833]" />
            <span class="sr-only"> Filtrér på </span> Medietype
          </summary>

          <ul>
            <li>
              <label
                v-for="[_, { name, ibexaId }] in [
                  ...articleTemplates,
                ].filter(
                  ([key]) =>
                    search.results.count.templates.get(key) !== 0,
                )"
                :key="name"
                v-wave
                class="grid w-full grid-cols-[max-content,1fr,max-content] items-center gap-5 border-b border-white border-opacity-20 p-5 text-left"
              >
                <input
                  v-model="search.templateIdFilter.value"
                  type="checkbox"
                  class="input-checkbox"
                  name="filter-type"
                  :value="ibexaId"
                />
                <span>{{ name }}</span>
                <span>
                  {{ search.results.count.templates.get(ibexaId) }}
                </span>
              </label>
            </li>
          </ul>
        </details>
      </div>
      <div
        v-if="
          search.loading.value === 0 &&
          search.results.data.length === 0
        "
        class="py-5"
        aria-live="assertive"
      >
        Ingen treff på søk «{{ search.query.value }}»
        <span class="sr-only"
          >{{
            search.templateIdFilter.value.length ||
            search.locationIdFilter.value.length
              ? " filtrert på " +
                activeMediaTypeFilters +
                (activeChapterFilters && activeMediaTypeFilters
                  ? " og "
                  : "") +
                activeChapterFilters
              : ""
          }}
        </span>
      </div>

      <div
        v-else-if="search.loading.value > 0 && nextPageAmount === 0"
        class="overflow-auto scrollbar-hide no-scrollbar"
      >
        <u-icon class="mt-5 scale-[1.33]" name="spinner" />
      </div>

      <div v-else class="overflow-auto scrollbar-hide no-scrollbar">
        <ul aria-live="polite">
          <li
            v-for="{ article, title, description } in search.results
              .data"
            :key="article.id"
            data-pendo="search-list-item"
          >
            <router-link
              class="border-b-width-[1px] flex flex-col border-white border-opacity-20 py-4 font-national2 sm:flex-row"
              :to="`/produkter/${route.params.product}/${
                route.params.variant
              }/sok?${route.fullPath.split('?')[1]}&cursor=${
                article.id
              }`"
            >
              <section class="basis-full">
                <div class="italic font-bold leading-7 uppercase">
                  {{ article.parentLocation?.priority }}.
                  {{ article.parentLocation?.content?._name }}
                </div>
                <div
                  class="font-bold leading-7 text-red-60"
                  v-html="title"
                />
                <div
                  class="leading-7 break-words font-national2"
                  v-html="description"
                />
              </section>
              <div
                v-if="showPreviews"
                class="mb-1 ml-1 !h-[33svh] overflow-hidden rounded-2xl pt-6 sm:basis-full sm:pl-6 sm:pt-0 md:hidden"
              >
                <UPreview :article="article" />
              </div>
              <div class="clear-right" />
            </router-link>
          </li>
        </ul>

        <div
          :ref="el => (loadMoreObserverListEl = el as HTMLDivElement)"
        />

        <u-skeleton
          v-for="n in nextPageAmount"
          :key="n"
          class="h-48 mt-5 rounded-md"
        />
      </div>
    </div>

    <div v-if="showPreviews" class="pb-12 <md:hidden">
      <ul class="flex flex-col gap-12 <md:hidden">
        <li
          v-for="{ article } in search.results.data"
          :key="article.id"
          data-pendo="search-card"
        >
          <router-link
            class="block h-[50svh] overflow-hidden rounded-[1.25rem]"
            :to="`/produkter/${route.params.product}/${
              route.params.variant
            }/sok?${route.fullPath.split('?')[1]}&cursor=${
              article.id
            }`"
            data-pendo="SearchPage-open_article_preview"
          >
            <UPreview :article="article" />
          </router-link>
        </li>
      </ul>

      <div
        :ref="
          el => (loadMoreObserverPreviewEl = el as HTMLDivElement)
        "
      />

      <u-skeleton
        v-for="index in nextPageAmount"
        :key="index"
        class="mt-12 h-128 rounded-2xl"
      />
    </div>
  </div>

  <dialog
    :ref="el => (dialogEl = el as HTMLDialogElement)"
    class="w-full h-full max-w-full max-h-full p-0"
  >
    <div v-if="activeCursorArticle != null" class="grid h-full">
      <nav
        class="fixed left-5 right-5 top-5 z-10 grid grid-cols-[minmax(0,1fr),repeat(2,max-content)] gap-3 stroke-black"
      >
        <UButtonNew
          :to="route.fullPath.split('&')[0]"
          variant="filled"
          theme="white"
          pill
          data-pendo="SearchPage-back_to_all_results"
        >
          <template #prepend>
            <u-icon
              name="search"
              class="mr-2 text-current"
              size="sm"
            />
          </template>
          <span class="font-national2 !leading-none">Søk: "</span>
          <span
            class="min-w-0 flex-shrink overflow-hidden overflow-ellipsis font-national2 !leading-none"
            >{{ search.query.value }}</span
          >
          <span class="font-national2 !leading-none"
            >" - {{ activeCursorIndex + 1 }} av
            {{ search.results.count.filteredTotal }}</span
          >
        </UButtonNew>

        <UButtonNew
          v-show="prevCursor !== null"
          pill
          :to="route.fullPath.split('&')[0] + '&cursor=' + prevCursor"
          :tabindex="prevCursor == null ? -1 : 0"
          :aria-disabled="prevCursor == null"
          variant="filled"
          theme="white"
          data-pendo="SearchPage-previous_result_button"
        >
          <u-icon name="arrow-left" size="sm" />
        </UButtonNew>

        <UButtonNew
          v-show="nextCursor !== null"
          pill
          :to="route.fullPath.split('&')[0] + '&cursor=' + nextCursor"
          :tabindex="nextCursor == null ? -1 : 0"
          :aria-disabled="nextCursor == null"
          variant="filled"
          theme="white"
          data-pendo="SearchPage-next_result_button"
        >
          <u-icon name="arrow-right" size="sm" />
        </UButtonNew>
      </nav>
      <u-dynamic-article
        :key="activeCursorArticle.id"
        v-highlight:search="search.query.value"
        class="z-0"
        :data="activeCursorArticle.article"
      />
    </div>
  </dialog>
</template>

<style scoped lang="postcss">
details summary::-webkit-details-marker {
  display: none;
}
details:not([open]) summary > i {
  @apply -rotate-90 transform;
}
.input-checkbox {
  @apply grid h-5 w-5 appearance-none place-items-center rounded-sm border-2 border-white leading-none;
  &:checked {
    @apply bg-white text-portal-indigo-900;
    background-image: url("/src/assets/icons/check.svg");
  }
}
</style>

<route lang="yaml">
name: search
meta:
  title: Søk
  layout: navigation
</route>
