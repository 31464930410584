import type { ContentProductPage } from "~/models/Content/ContentProductPage"
import { formatForURL } from "~/utilities/composables"
import { getContent } from "~/api/getContent"
import { ContentProductPageBlocks } from "~/models/Content/ContentProductPageBlocks"
import { ContentProductPageUsp } from "~/models/Content/ContentProductPageUsp"
import { ContentType } from "~/models/Content/ContentType"
import { ContentProductPageMediaTypes } from "~/models/Content/ContentProductPageMediaTypes"

const PRODUCTS_PAGES_LOCATION_ID = import.meta.env
  .VITE_CMS_PRODUCTS_PAGES_LOCATION_ID as string | "0"

export type ProductPageList = Record<string, ContentProductPage>

export async function getProductPageList(): Promise<ProductPageList | null> {
  const productPages = await getContent<ContentProductPage>(
    {
      contentTypeCriterion: ["product_page"],
      parentLocationIdCriterion: [
        parseInt(PRODUCTS_PAGES_LOCATION_ID),
      ],
      sortField: "priority",
    },
    100,
    0,
    [],
    { includeChildren: true },
  )

  return productPages.reduce<ProductPageList>((acc, page) => {
    // Filter and assign blocks and USPs from children
    const blocks = page.children.filter(
      child =>
        child.contentTypeIdentifier === "product_page_block" ||
        child.contentTypeIdentifier ===
          ContentType.ProductPageMediaTypes,
    ) as (ContentProductPageBlocks | ContentProductPageMediaTypes)[]

    const usp = page.children.find(
      child =>
        child.contentTypeIdentifier === ContentType.ProductPageUSP,
    ) as ContentProductPageUsp | undefined

    // Update the page with filtered children
    const updatedPage = {
      ...page,
      blocks: blocks,
      uspList: usp?.uspList ?? [],
    }

    // Create URL-friendly key and add to accumulator
    const key = formatForURL(
      page.title.replaceAll(" ", "-").toLowerCase(),
    )
    acc[key] = updatedPage
    return acc
  }, {})
}

export function isProductPageBlock(
  object: unknown,
): object is ContentProductPageBlocks {
  return (
    typeof object === "object" &&
    !!object &&
    "body" in object &&
    typeof object.body === "string" &&
    "header" in object &&
    typeof object.header === "string" &&
    "blockOrientation" in object &&
    typeof object.blockOrientation === "string"
  )
}

export function isProductPageMediaTypes(
  object: unknown,
): object is ContentProductPageMediaTypes {
  return (
    typeof object === "object" &&
    !!object &&
    "productMediaTypes" in object &&
    Array.isArray(object.productMediaTypes)
  )
}
