export enum ContentType {
  Product = "product",
  Article = "article",
  Chapter = "subject",
  ChapterPage = "subject_page",
  Folder = "folder",
  ProductPage = "product_page",
  ProductPageBlock = "product_page_block",
  ProductPageUSP = "product_page_usp",
  ProductPageMediaTypes = "product_page_media_types",
  ProductPages = "product_pages",
  Image = "image",
  Svg = "svg",
  SidenoteSource = "source",
  SidenoteTerm = "term",
  SidenotePerson = "person",
  Sidenote = "sidenote",
  SidenoteExplainer = "explainer",
  SidenoteLegalSource = "legal_source",
  SidenoteWhatIs = "what_is",
  Video = "video",
  Quiz = "quiz",
  Audio = "audio",
  Unknown = "unknown",
  MathTaskIbexa = "math_task",
  SolutionIbexa = "solution",
  SuggestedSolutionIbexa = "suggested_solution",
  SuggestedSolutionSectionIbexa = "suggested_solution_section",
  Caption = "caption",
  MarketingArticle = "marketing_article",
  MarketingArticleSection = "marketing_article_section",
  Page = "page",
  PageSection = "page_section",
}
