<script setup lang="ts">
import { KsSkeleton, KsSkeletonWrapper } from "@aschehoug/kloss"
import { ContentProductPageBlocks } from "~/models/Content/ContentProductPageBlocks"

const props = defineProps({
  productBlockData: {
    type: Object as PropType<ContentProductPageBlocks>,
    default: null,
  },
})

const blockOrientation = computed(
  () => props.productBlockData?.blockOrientation,
)

const isQuote = computed(() =>
  blockOrientation.value?.includes("quote"),
)
const isTextOnly = computed(() => blockOrientation.value === "text")
const isImageFirst = computed(() =>
  blockOrientation.value?.split("-")[0].includes("image"),
)
</script>

<template>
  <UProductPageBlockText
    v-if="isTextOnly"
    class="lg:max-w-[85ch]"
    :title="productBlockData?.header"
    :text="productBlockData?.body"
  />
  <section
    v-else
    :class="{
      'flex-row-reverse': !isImageFirst,
      'flex-row': isImageFirst,
    }"
    class="flex h-full w-full max-w-[90em] items-center justify-around gap-6 overflow-clip rounded-lg lg:max-h-[35em] lg:w-full lg:gap-24 <lg:flex-col"
  >
    <img
      v-if="productBlockData?.image?.src"
      :src="productBlockData?.image?.src"
      class="relative h-full max-h-[35em] w-full rounded-lg object-cover lg:h-[20em] lg:max-h-full lg:w-[50em] lg:max-w-[50%] xl:h-[21em]"
      :alt="productBlockData?.image?.alt"
    />

    <div
      v-if="productBlockData"
      class="flex h-fit w-full lg:max-w-[41.5em] flex-col items-center justify-center gap-10 pt-2 lg:w-[50vw] lg:items-start"
    >
      <div
        class="flex w-full flex-col items-start justify-center sm:w-fit"
      >
        <div
          class="flex h-full w-full flex-col items-start justify-center"
        >
          <h4
            class="ellpisis flex w-full justify-start overflow-clip font-national2condensed text-4xl font-extrabold text-[--theme-dark] lg:text-5xl 2xl:text-6xl 2xl:leading-[4rem]"
            :class="{
              'max-h-52 font-semibold normal-case italic': isQuote,
            }"
          >
            {{ productBlockData?.header }}
          </h4>
          <p
            class="line-clamp-12 w-fit text-pretty pt-2 font-numeraWebRegular text-xl font-light leading-normal tracking-wider text-[--theme-darkest] lg:text-2xl 2xl:line-clamp-8 2xl:text-3xl 2xl:leading-relaxed"
          >
            {{ productBlockData?.body }}
          </p>
        </div>
      </div>
    </div>
    <KsSkeletonWrapper
      v-else
      class="skeleton-wrapper flex h-full flex-col gap-4"
    >
      <KsSkeleton width="60%" height="5rem" class="mb-4" />
      <KsSkeleton height="3.5rem" />
      <KsSkeleton height="3.5rem" />
      <KsSkeleton width="90%" height="3.5rem" />
    </KsSkeletonWrapper>
  </section>
</template>

<style scoped>
.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: clip;
}
</style>
