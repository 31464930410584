<script setup lang="ts">
defineProps({
  preview: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  plainText: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  parentLocationId: Number,
  subjectPage: {
    type: Boolean,
    default: false,
  },
  isDark: {
    type: Boolean,
    default: false,
  },
})

const data = useProductContentStore()

const subject = computed(() => data.activeSubject)
const shortTitle = computed(() => data.activeSubject?.shortTitle)
const subjectHref = computed(() => data.activeSubject?.frontendHref)
const subjectPriority = computed(() => data.activeSubject?.priority)

const checkSubject = () =>
  (subjectHref.value && shortTitle.value) ||
  (subject.value?.href && subject.value?.shortTitle)
</script>

<template>
  <p
    v-if="checkSubject() && !plainText"
    class="mb-2 line-clamp-2 font-national2 text-xl font-extrabold uppercase xl:text-2xl"
  >
    <RouterLink
      :to="subjectHref"
      class="border-b-2 border-u-contrast"
      :class="{
        '!border-lighter !text-lighter': isDark,
      }"
      >{{ subject?.priority || subjectPriority }}.
      {{ shortTitle || subject?.shortTitle }}</RouterLink
    >
  </p>
  <p
    v-if="checkSubject() && plainText"
    class="border-b-2 border-u-contrast"
  >
    {{ subject?.priority || subjectPriority }}.
    {{ shortTitle || subject?.shortTitle }}
  </p>
</template>
