import { parse } from "valibot"
import { ContentType } from "~/models/Content/ContentType"
import type {
  SearchItem,
  SearchResult,
  Tag,
} from "~/models/Content/SearchResult"
import type { BaseItem } from "~/models/Content/BaseItem"
import type { ProductVariants } from "~/models/Content/ContentProducts"
import type { ContentProductPage } from "~/models/Content/ContentProductPage"
import type { ContentProductPageBlocks } from "~/models/Content/ContentProductPageBlocks"
import type { ContentProductPageUsp } from "~/models/Content/ContentProductPageUsp"
import type {
  MarketingArticleContent,
  MarketingArticleSectionContent,
} from "~/types/MarketingArticle"
import type { PageData, PageSection } from "~/types/pages"
import {
  Article,
  ExplainerRelation,
  PersonRelation,
  LegalSourceRelation,
  SourceRelation,
  TermRelation,
  WhatIsRelation,
} from "~/types/article"
import {
  ContentProductPageMediaTypes,
  MEDIA_TYPE_ICONS,
} from "~/models/Content/ContentProductPageMediaTypes"

// Add schema imports
import {
  ArticleSchema,
  AudioSchema,
  BaseItemSchema,
  ChapterSchema,
  ContentProductPageBlocksSchema,
  ContentProductPageMediaTypesSchema,
  ContentProductPagesSchema,
  ContentProductPageUspSchema,
  ExplainerSidenoteSchema,
  FolderSchema,
  MarketingArticleSchema,
  MarketingArticleSectionSchema,
  MathTaskIbexaSchema,
  MathTaskIbexaSolutionSchema,
  MathTaskIbexaSuggestedSolutionSchema,
  MathTaskIbexaSuggestedSolutionSectionSchema,
  PageSchema,
  PageSectionSchema,
  PersonSidenoteSchema,
  ProductVariantsSchema,
  QuizSchema,
  SourceSidenoteSchema,
  LegalSourceSidenoteSchema,
  TermSidenoteSchema,
  VideoSchema,
  WhatIsSidenoteSchema,
  ChapterPageSchema,
  MathTaskIbexa,
  MathTaskIbexaSolution,
  MathTaskIbexaSuggestedSolution,
  MathTaskIbexaSuggestedSolutionSection,
  CaptionSchema,
} from "~/models/Content/schemas"

import * as Sentry from "@sentry/vue"
import { Audio, Caption, Quiz, Video } from "~/types/media"
import { htmlToPlaintext } from "~/utilities/dom-parsing"
import { Subject } from "~/types/subject"
import { Folder } from "~/types/folder"
import type { SubjectPage } from "~/types/subject"

// Main mapping function
export const mapContents = <T extends BaseItem>(
  result: SearchResult,
): T[] => {
  if (!result.items.length) {
    return []
  }

  return result.items.map(searchItem => {
    let mappedItem = {} as BaseItem
    try {
      // Combined mapping and validation
      switch (searchItem.contentType) {
        case ContentType.Product:
          mappedItem = mapProduct(searchItem)
          parse(ProductVariantsSchema, mappedItem)
          break
        case ContentType.ProductPage:
          mappedItem = mapProductPage(searchItem)
          parse(ContentProductPagesSchema, mappedItem)
          break
        case ContentType.ProductPageBlock:
          mappedItem = mapProductPageBlock(searchItem)
          parse(ContentProductPageBlocksSchema, mappedItem)
          break
        case ContentType.ProductPageUSP:
          mappedItem = mapProductPageUSP(searchItem)
          parse(ContentProductPageUspSchema, mappedItem)
          break
        case ContentType.ProductPageMediaTypes:
          mappedItem = mapProductPageMediaTypes(searchItem)
          parse(ContentProductPageMediaTypesSchema, mappedItem)
          break
        case ContentType.Article:
          mappedItem = mapArticle(searchItem)
          parse(ArticleSchema, mappedItem)
          break
        case ContentType.Video:
          mappedItem = mapVideo(searchItem)
          parse(VideoSchema, mappedItem)
          break
        case ContentType.Quiz:
          mappedItem = mapQuiz(searchItem)
          parse(QuizSchema, mappedItem)
          break
        case ContentType.Audio:
          mappedItem = mapAudio(searchItem)
          parse(AudioSchema, mappedItem)
          break
        case ContentType.SidenoteSource:
          mappedItem = mapSidenoteSource(searchItem)
          parse(SourceSidenoteSchema, mappedItem)
          break
        case ContentType.SidenoteTerm:
          mappedItem = mapSidenoteTerm(searchItem)
          parse(TermSidenoteSchema, mappedItem)
          break
        case ContentType.SidenotePerson:
          mappedItem = mapSidenotePerson(searchItem)
          parse(PersonSidenoteSchema, mappedItem)
          break
        case ContentType.Sidenote:
        case ContentType.SidenoteExplainer:
          mappedItem = mapSidenoteExplainer(searchItem)
          parse(ExplainerSidenoteSchema, mappedItem)
          break
        case ContentType.SidenoteLegalSource:
          mappedItem = mapSidenoteLegalSource(searchItem)
          parse(LegalSourceSidenoteSchema, mappedItem)
          break
        case ContentType.SidenoteWhatIs:
          mappedItem = mapSidenoteWhatIs(searchItem)
          parse(WhatIsSidenoteSchema, mappedItem)
          break
        case ContentType.MathTaskIbexa:
          mappedItem = mapMathTaskIbexa(searchItem)
          parse(MathTaskIbexaSchema, mappedItem)
          break
        case ContentType.SolutionIbexa:
          mappedItem = mapIbexaSolution(searchItem)
          parse(MathTaskIbexaSolutionSchema, mappedItem)
          break
        case ContentType.SuggestedSolutionIbexa:
          mappedItem = mapMathTaskIbexaSuggestedSolution(searchItem)
          parse(MathTaskIbexaSuggestedSolutionSchema, mappedItem)
          break
        case ContentType.SuggestedSolutionSectionIbexa:
          mappedItem =
            mapMathTaskIbexaSuggestedSolutionSection(searchItem)
          parse(
            MathTaskIbexaSuggestedSolutionSectionSchema,
            mappedItem,
          )
          break
        case ContentType.Image:
          mappedItem = mapImage(searchItem)
          break
        case ContentType.Folder:
          mappedItem = mapFolder(searchItem)
          parse(FolderSchema, mappedItem)
          break
        case ContentType.Chapter:
          mappedItem = mapChapter(searchItem)
          parse(ChapterSchema, mappedItem)
          break
        case ContentType.ChapterPage:
          mappedItem = mapChapterPage(searchItem)
          parse(ChapterPageSchema, mappedItem)
          break
        case ContentType.MarketingArticle:
          mappedItem = mapMarketingArticle(searchItem)
          parse(MarketingArticleSchema, mappedItem)
          break
        case ContentType.MarketingArticleSection:
          mappedItem = mapMarketingArticleSection(searchItem)
          parse(MarketingArticleSectionSchema, mappedItem)
          break
        case ContentType.Page:
          mappedItem = mapPage(searchItem)
          parse(PageSchema, mappedItem)
          break
        case ContentType.PageSection:
          mappedItem = mapPageSection(searchItem)
          parse(PageSectionSchema, mappedItem)
          break
        case ContentType.Caption:
          mappedItem = mapCaption(searchItem)
          parse(CaptionSchema, mappedItem)
          break
        default:
          mappedItem = mapBaseFields(searchItem)
          console.warn(
            `[Mapping] No mapping found for content type: ${searchItem.contentType}`,
          )
          parse(BaseItemSchema, mappedItem)
          break
      }

      logMappedContent(mappedItem, searchItem, {
        logAll: true,
        contentTypes: [],
        includeNullValues: false,
        compareFields: [],
        showOnlyDifferences: false,
        disable: true, //!isFeatureEnabled.value,
      })

      // Validate the mapped item based on its content type
    } catch (error) {
      // Extract validation issues from ValiError
      let issues: Array<{ path?: any[]; message: string }> = []

      if (error instanceof Error && "issues" in error) {
        issues = (error as any).issues
      }

      // Format validation errors
      const validationErrors = issues.map(issue => ({
        locationId: searchItem.metadata.locationId,
        contentId: searchItem.id,
        field: issue.path?.[0]?.key || "unknown field",
        message: issue.message,
        receivedValue: issue.path?.[0]?.value || "undefined",
      }))

      console.error(
        `Validation failed for content type ${searchItem.contentType}:`,
        validationErrors,
        error,
      )

      Sentry.captureException(error, {
        extra: {
          contentType: searchItem.contentType,
          itemId: searchItem.id,
          validationErrors,
        },
      })
    }
    return mappedItem as T
  })
}

// Base mapper function
const mapBaseFields = (searchItem: SearchItem): BaseItem => {
  const { fields, metadata } = searchItem

  return {
    title: fields.title?.value || "",
    shortTitle: fields.short_title?.value || "",
    contentId: searchItem.id,
    remoteId: metadata.remoteId,
    locationId: metadata.locationId,
    mainLocationId: metadata.mainLocationId,
    parentLocationId: metadata.parentLocationId,
    contentTypeIdentifier: searchItem.contentType,
    pathString: metadata.pathString,
    publishedDate: metadata.published,
    label: fields.label?.value || "",
    thumbnail: fields.thumbnail?.value?.src || "",
    thumbnailAlt: fields.thumbnail?.value?.alt || "",
    sortField: metadata.sortField,
    sortOrder: metadata.sortOrder,
    priority: metadata.priority,
    relationsIds: searchItem.relationIds,
    children: [], // Are populated in the getContents file
    relatedItems: [], // Are populated in the getContents file based on the relationsIds
    childCount: metadata.childCount || 0,
  }
}

// Content type specific mappers
const mapProduct = (searchItem: SearchItem): ProductVariants => {
  const baseFields = mapBaseFields(searchItem)
  const { fields } = searchItem

  // TODO: this logic is for backwards compatibility and once data has been
  // migrated, we can remove this
  const oldStatus = fields.status?.value[0]?.value
  let newStatus = fields.status?.value[0]?.key || "in-development"
  if (oldStatus) {
    switch (oldStatus) {
      case "Coming Soon":
        console.warn(
          `[Status Migration] Found outdated status "Coming Soon" for product ${searchItem.id}. Please update to "coming-soon"`,
        )
        Sentry.captureMessage(
          `[Status Migration] Found outdated status "Coming Soon"`,
          {
            level: "warning",
            extra: {
              productId: searchItem.id,
              oldStatus: "Coming Soon",
              newStatus: "coming-soon",
            },
          },
        )
        newStatus = "coming-soon"
        break
      case "Full Release":
        console.warn(
          `[Status Migration] Found outdated status "Full Release" for product ${searchItem.id}. Please update to "full-release"`,
        )
        Sentry.captureMessage(
          `[Status Migration] Found outdated status "Full Release"`,
          {
            level: "warning",
            extra: {
              productId: searchItem.id,
              oldStatus: "Full Release",
              newStatus: "full-release",
            },
          },
        )
        newStatus = "full-release"
        break
      case "Partial Release":
        console.warn(
          `[Status Migration] Found outdated status "Partial Release" for product ${searchItem.id}. Please update to "partial-release"`,
        )
        Sentry.captureMessage(
          `[Status Migration] Found outdated status "Partial Release"`,
          {
            level: "warning",
            extra: {
              productId: searchItem.id,
              oldStatus: "Partial Release",
              newStatus: "partial-release",
            },
          },
        )
        newStatus = "partial-release"
        break
    }
  }

  return {
    ...baseFields,
    ibexaTitle: fields.ibexa_title?.value || "",
    titleHtml: fields.title_html?.value,
    ean: fields.ean?.value,
    pensumFor: fields.pensum_for?.value || "",
    institute:
      fields.institute?.value.map((tag: Tag) => tag.value) || [],
    status: newStatus,
    _location: {
      id: searchItem.metadata.locationId,
      pathString: searchItem.metadata.pathString,
    },
    isFree: fields.is_free?.value ?? false,
  }
}

const mapProductPage = (
  searchItem: SearchItem,
): ContentProductPage => {
  const baseFields = mapBaseFields(searchItem)
  const { fields } = searchItem

  return {
    ...baseFields,
    header: {
      html5: fields.header?.value || "",
    },
    quote: fields.quote?.value || "",
    groupSalesPitch: {
      html5: fields.group_sales_pitch?.value || "",
    },
    theme: fields.theme?.value[0]?.value || "",
    blocks: [], // this is a different content type which should be higher in the stack
    uspList: [],
    productGroupStatusDetails:
      fields.product_group_status_details?.value || "",
  }
}

const mapProductPageBlock = (
  searchItem: SearchItem,
): ContentProductPageBlocks => {
  const baseFields = mapBaseFields(searchItem)
  const { fields } = searchItem
  return {
    ...baseFields,
    body: fields.body?.value || "",
    header: fields.header?.value || "",
    imageText: fields.image_text?.value || "",
    image: fields.image?.value || null,
    blockOrientation:
      fields.block_orientation?.value?.[0]?.value || "",
  }
}

const mapProductPageUSP = (
  searchItem: SearchItem,
): ContentProductPageUsp => {
  const baseFields = mapBaseFields(searchItem)
  const { fields } = searchItem

  return {
    ...baseFields,
    uspList: [
      fields.usp_1?.value || "",
      fields.usp_2?.value || "",
      fields.usp_3?.value || "",
      fields.usp_4?.value || "",
    ],
  }
}

const mapProductPageMediaTypes = (
  searchItem: SearchItem,
): ContentProductPageMediaTypes => {
  const baseFields = mapBaseFields(searchItem)
  const { fields } = searchItem

  const mappedTags = fields.product_media_types?.value.map(
    (tag: Tag) => ({
      text: tag.value,
      icon: MEDIA_TYPE_ICONS[tag.key],
    }),
  )

  return {
    ...baseFields,
    productMediaTypes: mappedTags,
  }
}

const mapArticle = (searchItem: SearchItem): Article => {
  const baseFields = mapBaseFields(searchItem)
  const { fields } = searchItem
  return {
    ...baseFields,
    id: baseFields.contentId,
    template: fields.template?.value || "",
    titleHtml: fields.title_html?.value || undefined,
    img: fields.image?.value || null,
    imageCaption: {
      html: fields.imageCaption?.value || "",
    },
    imageSize: fields.image_size?.value as
      | "Large"
      | "Medium"
      | "Small"
      | undefined,
    intro: {
      html: fields.intro?.value || "",
      plaintext: htmlToPlaintext(fields.intro?.value || ""),
    },
    body: {
      html: fields.body?.value || "",
      plaintext: htmlToPlaintext(fields.body?.value || ""),
    },
    bg: fields.bg?.value || undefined,
    color: fields.color?.value || undefined,
    hasPictureFrame: fields.has_picture_frame?.value || undefined,
    pictureColor: fields.picture_color?.[0]?.value || undefined,
    pictureText: {
      html: fields.picture_text?.value || "",
    },
    type: baseFields.contentTypeIdentifier,
  }
}

const mapVideo = (searchItem: SearchItem): Video => {
  const baseFields = mapBaseFields(searchItem)
  const { fields } = searchItem

  return {
    ...baseFields,
    id: baseFields.contentId.toString(),
    title: fields.title?.value || "",
    previewTitle: fields.previewTitle?.value || "",
    img: fields.image?.value || null,
    publishedBy: fields.publishedBy?.value || "",
    published: fields.published?.value || "",
    timestamps: (fields.timestamps?.value?.entries || []).map(
      (stamp: any, key: number) => ({
        id: `${baseFields.contentId}-video-timestamp-${key}`,
        ...stamp,
      }),
    ),
    embedCode: fields.embed_code?.value || "",
    transcription: {
      html: fields.transcription?.value || "",
    },
    metadata: fields.metadata.value,
    _type: {
      identifier: searchItem.contentType || "",
    },
    captions: [],
  }
}

const mapAudio = (searchItem: SearchItem): Audio => {
  const baseFields = mapBaseFields(searchItem)
  const { fields } = searchItem
  return {
    ...baseFields,
    title: fields.title?.value || "",
    shortTitle: fields.shortTitle?.value || "",
    caption: fields.caption?.value || "",
    timestamps: fields.timestamps?.value?.entries || [],
    id: baseFields.contentId.toString(),
    metadata: fields.metadata.value,
  }
}

const mapQuiz = (searchItem: SearchItem): Quiz => {
  const baseFields = mapBaseFields(searchItem)
  const { fields } = searchItem
  return {
    ...baseFields,
    id: fields.quiz_id?.value || "",
    title: fields.title?.value || "",
  }
}

const mapBaseImage = (image: any) => {
  if (!image) return undefined
  return {
    id: null,
    src: image.src || "",
    alt: image.alt || "",
    width: image.width || undefined,
    height: image.height || undefined,
    focalPointX: image.focalPointX || undefined,
    focalPointY: image.focalPointY || undefined,
  }
}

const mapBaseSidenoteFields = (fields: any) => {
  return {
    identifier: fields.identifier?.value || "",
    title: fields.title?.value || "",
    html: fields.html?.value || null,
    resourceUrl: fields.resourceUrl?.value || "",
    image: fields.image?.value || undefined,
    imageCaption: fields.imageCaption?.value,
    video: fields.video?.value?.contentIds || [],
    appendText: fields.appendText?.value,
  }
}

const mapSidenoteSource = (
  searchItem: SearchItem,
): SourceRelation => {
  const { fields } = searchItem
  const baseFieldsSideNotes = mapBaseSidenoteFields(fields)
  const baseFields = mapBaseFields(searchItem)

  return {
    ...baseFields,
    ...baseFieldsSideNotes,
    identifier: "source",
    resource: {
      type: fields.type?.value || "",
      author: fields.author?.value || "",
      title: fields.title?.value || "",
      edition: fields.edition?.value,
      publisher: fields.publisher?.value,
      publishYear: fields.publish_year?.value
        ? Number(fields.publish_year.value)
        : undefined,
      publishDate: fields.publish_date?.value
        ? fields.publish_date.value
        : null,
      url: fields.url?.value?.link || undefined,
    },
  }
}

const mapSidenoteLegalSource = (
  searchItem: SearchItem,
): LegalSourceRelation => {
  const { fields } = searchItem
  const baseFieldsSideNotes = mapBaseSidenoteFields(fields)
  const baseFields = mapBaseFields(searchItem)

  return {
    ...baseFields,
    ...baseFieldsSideNotes,
    identifier: "legal_source",
    resource: {
      type: fields.type?.value?.[0]?.value,
      text: fields.text?.value || "",
      title: fields.title?.value || "",
      url: fields.url?.value || undefined,
      paragraphAndSection: fields.paragraph_and_section?.value || "",
      urlTitle: fields.url_title?.value || "",
      typeDescription: fields.type_description?.value || "",
    },
  }
}

const mapSidenoteTerm = (searchItem: SearchItem): TermRelation => {
  const { fields } = searchItem
  const baseFieldsSideNotes = mapBaseSidenoteFields(fields)
  const baseFields = mapBaseFields(searchItem)

  return {
    ...baseFields,
    ...baseFieldsSideNotes,
    identifier: "term",
    resource: {
      definition: fields.definition?.value || "",
      explanation: fields.explanation?.value || "",
      isFlashcard: fields.isFlashcard?.value || false,
    },
  }
}

const mapSidenotePerson = (
  searchItem: SearchItem,
): PersonRelation => {
  const { fields } = searchItem
  const baseFieldsSideNotes = mapBaseSidenoteFields(fields)
  const baseFields = mapBaseFields(searchItem)

  return {
    ...baseFields,
    ...baseFieldsSideNotes,
    title: fields.name?.value || "",
    identifier: "person",
    resource: {
      birthYear: fields.birth_year?.value ?? 0,
      deceasedYear: fields.deceased_year?.value ?? undefined,
      about: fields.about?.value || "",
    },
  }
}

const mapSidenoteExplainer = (
  searchItem: SearchItem,
): ExplainerRelation => {
  const { fields } = searchItem
  const baseFieldsSideNotes = mapBaseSidenoteFields(fields)
  const baseFields = mapBaseFields(searchItem)

  return {
    ...baseFields,
    ...baseFieldsSideNotes,
    identifier:
      fields.identifier?.value === "explainer"
        ? "explainer"
        : "sidenote",
    html: fields.richtext?.value || "",
  }
}

const mapSidenoteWhatIs = (
  searchItem: SearchItem,
): WhatIsRelation => {
  const { fields } = searchItem
  const baseFieldsSideNotes = mapBaseSidenoteFields(fields)
  const baseFields = mapBaseFields(searchItem)

  return {
    ...baseFields,
    ...baseFieldsSideNotes,
    identifier: "what_is",
    resource: {
      explanation: fields.explanation?.value || "",
    },
  }
}

function mapMathTaskIbexa(searchItem: SearchItem): MathTaskIbexa {
  const baseFields = mapBaseFields(searchItem)
  const { fields } = searchItem
  return {
    ...baseFields,
    assignmentType: fields.assignment_type?.value || "",
    assignmentText: {
      html: fields.assignment_text?.value || "",
      plaintext: htmlToPlaintext(fields.assignment_text?.value || ""),
    },
  }
}

const mapIbexaSolution = (
  searchItem: SearchItem,
): MathTaskIbexaSolution => {
  const { fields } = searchItem
  const baseFields = mapBaseFields(searchItem)
  return {
    ...baseFields,
    solutionTitle: fields.solution_title?.value || "",
    solutionText: {
      html: fields.solution_text?.value || "",
      plaintext: htmlToPlaintext(fields.solution_text?.value || ""),
    },
  }
}

const mapMathTaskIbexaSuggestedSolution = (
  searchItem: SearchItem,
): MathTaskIbexaSuggestedSolution => {
  const { fields } = searchItem
  const baseFields = mapBaseFields(searchItem)
  return {
    ...baseFields,
    title: fields.title?.value || "",
  }
}

const mapMathTaskIbexaSuggestedSolutionSection = (
  searchItem: SearchItem,
): MathTaskIbexaSuggestedSolutionSection => {
  const { fields } = searchItem
  const baseFields = mapBaseFields(searchItem)
  return {
    ...baseFields,
    sectionBody: {
      html: fields.section_body?.value || "",
      plaintext: htmlToPlaintext(fields.section_body?.value || ""),
    },
    sectionImage: mapBaseImage(fields.section_image?.value),
  }
}

const mapImage = (searchItem: SearchItem) => {
  const { fields } = searchItem
  const baseFields = mapBaseFields(searchItem)
  return {
    ...baseFields,
    ...(mapBaseImage(fields?.image?.value) ?? {}),
    detailedDescription:
      fields.detailed_image_description?.value || "",
  }
}

const mapFolder = (searchItem: SearchItem): Folder => {
  const baseFields = mapBaseFields(searchItem)
  const { fields } = searchItem

  return {
    ...baseFields,
    id: baseFields.locationId,
    colorTag: fields.color_tag?.value[0]?.value || "",
    titleHtml: fields.title_html?.value || "",
    img: mapBaseImage(fields.image?.value),
    hidden: fields.hidden?.value || false,
    type: searchItem.contentType,
  }
}

const mapChapter = (searchItem: SearchItem): Subject => {
  const baseFields = mapBaseFields(searchItem)
  const { fields } = searchItem

  return {
    ...baseFields,
    id: baseFields.locationId,
    hidden: fields.hidden?.value || false,
    subjectPageTitlePosition:
      fields.subject_page_title_position?.value,
    introPages: [], // will be mapped higher up in the chain
    colorTag: fields.color_tag?.value[0]?.value || "",
    chapterType: fields.chapter_type?.value || "Kun fargefyll",
    description: fields.description.value || "",
    rawHref: "",
    href: "",
    img: mapBaseImage(fields.image?.value),
    totalChildren: baseFields.childCount,
    type: searchItem.contentType,
    introPagesContentIds: fields.intro_pages?.value?.contentIds || [],
    isFree: fields.is_free?.value ?? false,
  }
}

const mapChapterPage = (searchItem: SearchItem): SubjectPage => {
  const baseFields = mapBaseFields(searchItem)
  const { fields } = searchItem

  return {
    ...baseFields,
    id: baseFields.locationId,
    title: fields.title?.value || "",
    template: fields.template?.value || "",
    richtext: fields.richtext?.value
      ? {
          html: fields.richtext.value,
          plaintext: htmlToPlaintext(fields.richtext.value),
        }
      : undefined,
    colorTheme: fields.color_theme?.value?.[0]?.value || "",
    image: mapBaseImage(fields.image?.value),
  }
}

const mapMarketingArticle = (
  searchItem: SearchItem,
): MarketingArticleContent => {
  const baseFields = mapBaseFields(searchItem)
  const { fields } = searchItem

  return {
    ...baseFields,
    subtitle: {
      html5: fields.subtitle?.value || "",
    },
    tags: fields.tags?.value || [],
  }
}

const mapMarketingArticleSection = (
  searchItem: SearchItem,
): MarketingArticleSectionContent => {
  const baseFields = mapBaseFields(searchItem)
  const { fields } = searchItem

  return {
    ...baseFields,
    id: baseFields.locationId.toString(),
    sectionBody: {
      html5: fields.section_body?.value || "",
    },
    sectionBodyIsAQuote:
      fields.section_body_is_a_quote?.value || false,
    sectionTitle: fields.section_title?.value || "",
    buttonText: fields.button_text?.value || "",
    buttonUrl: fields.button_url?.value || "",
    image: mapBaseImage(fields.image?.value),
  }
}

const mapPage = (searchItem: SearchItem): PageData => {
  const baseFields = mapBaseFields(searchItem)
  const { fields } = searchItem

  return {
    ...baseFields,
    id: baseFields.locationId,
    urlAliases: fields.url_aliases?.value || [],
    route: {
      path: fields.route_path?.value || "",
      name: fields.route_name?.value || "",
    },
    title: fields.title?.value || "",
    subtitle: fields.subtitle?.value || "",
    metaImage: mapBaseImage(fields.meta_image?.value),
    metaTitle: fields.meta_title?.value || "",
    metaDescription: fields.meta_description?.value || "",
    layout: fields.layout?.value || "",
  }
}

const mapPageSection = (searchItem: SearchItem): PageSection => {
  const baseFields = mapBaseFields(searchItem)
  const { fields } = searchItem

  return {
    ...baseFields,
    id: baseFields.locationId,
    image: fields.image?.value
      ? {
          src: fields.image.value.src || undefined,
          alt: fields.image.value.alt || undefined,
        }
      : undefined,
    richtext: fields.richtext?.value || "",
  }
}

const mapCaption = (searchItem: SearchItem): Caption => {
  const baseFields = mapBaseFields(searchItem)
  const { fields } = searchItem

  return {
    ...baseFields,
    captionFile: {
      uri: fields.caption_file?.value?.uri || "",
      fileName: fields.caption_file?.value?.fileName || "",
    },
    language: fields.language?.value?.[0]?.value || "",
  }
}

interface LogMappingConfig {
  logAll?: boolean
  contentTypes?: ContentType[]
  includeNullValues?: boolean
  compareFields?: string[]
  showOnlyDifferences?: boolean
  disable?: boolean
}

const logMappedContent = (
  mappedItem: BaseItem,
  searchItem: SearchItem,
  config: Required<LogMappingConfig>,
) => {
  // Skip all logging if disabled
  if (config.disable) {
    return
  }

  // Skip logging if content type doesn't match filter
  if (
    config.contentTypes.length > 0 &&
    !config.contentTypes.includes(searchItem.contentType)
  ) {
    return
  }

  console.group(
    `Content Mapping Results for ${searchItem.contentType} (ID: ${searchItem.id})`,
  )

  // Log metadata comparison
  console.group("Metadata Comparison:")
  const metadataComparison = {
    contentId: {
      mapped: mappedItem.contentId,
      source: searchItem.id,
      matches: mappedItem.contentId === searchItem.id,
    },
    locationId: {
      mapped: mappedItem.locationId,
      source: searchItem.metadata.locationId,
      matches:
        mappedItem.locationId === searchItem.metadata.locationId,
    },
    remoteId: {
      mapped: mappedItem.remoteId,
      source: searchItem.metadata.remoteId,
      matches: mappedItem.remoteId === searchItem.metadata.remoteId,
    },
    pathString: {
      mapped: mappedItem.pathString,
      source: searchItem.metadata.pathString,
      matches:
        mappedItem.pathString === searchItem.metadata.pathString,
    },
    sortField: {
      mapped: mappedItem.sortField,
      source: searchItem.metadata.sortField,
      matches: mappedItem.sortField === searchItem.metadata.sortField,
    },
    sortOrder: {
      mapped: mappedItem.sortOrder,
      source: searchItem.metadata.sortOrder,
      matches: mappedItem.sortOrder === searchItem.metadata.sortOrder,
    },
  }

  const mismatchedMetadata = Object.entries(
    metadataComparison,
  ).filter(([_, value]) => !value.matches)

  if (config.showOnlyDifferences && mismatchedMetadata.length > 0) {
    console.log("❌ Mismatched Metadata:")
    console.table(Object.fromEntries(mismatchedMetadata))
  } else if (!config.showOnlyDifferences) {
    console.table(metadataComparison)
  }
  console.groupEnd()

  // Log field values comparison
  console.group("Fields Comparison:")
  const fieldComparison: Record<string, any> = {}
  const mismatchedFields: Record<string, any> = {}

  const fieldsToCompare =
    config.compareFields.length > 0
      ? config.compareFields
      : Object.keys(searchItem.fields)

  fieldsToCompare.forEach(fieldKey => {
    const sourceField = searchItem.fields[fieldKey]
    const mappedValue = (mappedItem as any)[fieldKey]

    if (
      !config.includeNullValues &&
      (mappedValue === null || mappedValue === undefined)
    ) {
      return
    }

    const comparison = {
      mapped: mappedValue,
      source: sourceField?.value,
      sourceType: sourceField?.type,
      matches:
        JSON.stringify(mappedValue) ===
        JSON.stringify(sourceField?.value),
    }

    fieldComparison[fieldKey] = comparison

    if (!comparison.matches) {
      mismatchedFields[fieldKey] = {
        ...comparison,
        difference: getDifference(mappedValue, sourceField?.value),
      }
    }
  })

  if (
    config.showOnlyDifferences &&
    Object.keys(mismatchedFields).length > 0
  ) {
    console.log("❌ Mismatched Fields:")
    console.table(mismatchedFields)
  } else if (!config.showOnlyDifferences) {
    console.table(fieldComparison)
  }
  console.groupEnd()

  // Log missing fields
  const sourceFields = new Set(Object.keys(searchItem.fields))
  const mappedFields = new Set(Object.keys(mappedItem))
  const missingFields = [...sourceFields].filter(
    x => !mappedFields.has(x),
  )

  if (missingFields.length > 0) {
    console.group("⚠️ Missing Fields (in mapped item):")
    console.table(
      missingFields.map(field => ({
        field,
        sourceType: searchItem.fields[field]?.type,
        sourceValue: searchItem.fields[field]?.value,
      })),
    )
    console.groupEnd()
  }

  if (config.logAll) {
    console.group("All Mapped Fields:")
    const allFields = Object.entries(mappedItem).filter(
      ([key]) => !Object.keys(metadataComparison).includes(key),
    )
    console.table(
      Object.fromEntries(
        allFields.map(([key, value]) => [
          key,
          {
            value,
            type: typeof value,
            hasSource: key in searchItem.fields,
          },
        ]),
      ),
    )
    console.groupEnd()
  }

  console.groupEnd()
}

// Helper function to get a meaningful difference description
const getDifference = (mapped: any, source: any): string => {
  if (typeof mapped !== typeof source) {
    return `Type mismatch: ${typeof mapped} vs ${typeof source}`
  }

  if (Array.isArray(mapped) && Array.isArray(source)) {
    if (mapped.length !== source.length) {
      return `Array length mismatch: ${mapped.length} vs ${source.length}`
    }
    return "Array contents differ"
  }

  if (typeof mapped === "object" && mapped !== null) {
    const mappedKeys = Object.keys(mapped)
    const sourceKeys = Object.keys(source || {})
    const keysDiff = mappedKeys
      .filter(k => !sourceKeys.includes(k))
      .concat(sourceKeys.filter(k => !mappedKeys.includes(k)))

    if (keysDiff.length > 0) {
      return `Different keys: ${keysDiff.join(", ")}`
    }
    return "Object contents differ"
  }

  return `Values differ: ${JSON.stringify(mapped)} vs ${JSON.stringify(source)}`
}
