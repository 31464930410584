<script setup lang="ts">
import type { Article } from "~/types/article"
import { getColorClassNameFromScheme as getColorClassName } from "~/utilities/colors"

const props = defineProps({
  data: {
    type: Object as PropType<Article>,
    default: () => {},
  },
  defaultPictureFrameColors: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const getPositionX = () => {
  if (!props.data?.img?.focalPointX) return 50
  return (props.data.img.focalPointX / props.data.img.width) * 100
}

const getPositionY = () => {
  if (!props.data?.img?.focalPointY) return 50
  return (props.data.img.focalPointY / props.data.img.height) * 100
}

const imageClasses = computed(() => {
  if (!props.data.hasPictureFrame) return ""

  switch (props.data.template) {
    case "UTextArticle": {
      switch (props.data.imageSize) {
        case "Small":
          return props.data.pictureText?.html
            ? "@md:col-start-5 @md:col-end-[-2]"
            : "@md:col-start-2 @md:col-end-[-2]"
        case "Medium":
          return props.data.pictureText?.html
            ? "@md:col-start-7 @md:col-end-[-2]"
            : "@md:col-start-4 @md:col-end-[-4]"
        default:
          return props.data.pictureText?.html
            ? "@md:col-start-8 @md:col-end-[-2]"
            : "@md:col-start-5 @md:col-end-[-5]"
      }
    }

    case "UImageWithTextArticle":
    case "UTextWithImageArticle":
    case "UMathTaskWithImageArticle": {
      return "@md:col-start-2 @md:col-end-[-2]"
    }

    default:
      return ""
  }
})

const captionClasses = computed(() => {
  if (props.data.template !== "UTextArticle")
    return imageClasses.value
  switch (props.data.imageSize) {
    case "Small":
      return "@md:col-start-2 @md:col-end-5"
    case "Medium":
      return "@md:col-start-2 @md:col-end-6"
    default:
      return "@md:col-start-5 @md:col-end-8"
  }
})
</script>

<template>
  <div
    class="image relative"
    :class="{
      'image--frame u-subgrid grid': data.hasPictureFrame,
      [getColorClassName(data.pictureColor, ['bg', 'text'], {
        bwText: true,
      }) ?? '']: data.pictureColor,
      'bg-white text-black':
        data.hasPictureFrame && defaultPictureFrameColors,
      'image--left':
        data.template === 'UImageWithTextArticle' ||
        data.template === 'UMathTaskWithImageArticle',
      'image--right': data.template === 'UTextWithImageArticle',
      'image--horizontal': data.template === 'UTextArticle',
    }"
  >
    <figure style="display: contents">
      <img
        class="col-start-2 col-end-18"
        :class="{
          [imageClasses]: true,
          '@md:order-2': data.template === 'UTextArticle',
        }"
        :src="data.img?.src || '/media/no-image.jpeg'"
        :alt="data.img?.alt"
        :aria-describedby="
          data.img?.detailedDescription
            ? 'detailed-image-description'
            : undefined
        "
        :style="
          data.hasPictureFrame
            ? ''
            : {
                'object-position': `${getPositionX()}% ${getPositionY()}%`,
              }
        "
      />
      <URichText
        v-if="data.img?.detailedDescription"
        id="detailed-image-description"
        class="sr-only"
        :html="data.img?.detailedDescription"
      />
      <figcaption
        v-if="data.hasPictureFrame && data.pictureText?.html"
        class="col-start-2 col-end-18 self-center font-national2"
        :class="{
          '@md:order-1': data.template === 'UTextArticle',
          [captionClasses]: true,
        }"
        v-html="data.pictureText.html"
      />
    </figure>
    <!-- <h1 v-if="data.hasPictureFrame" class="text-6xl font-bold font-national2compressed" :class="getColorClassNameFromScheme(data.pictureColor, ['text']) ?? ''">{{ data.title }}</h1> -->
    <u-image-caption
      v-if="data?.img"
      class="absolute bottom-0 right-0 z-40 w-full text-black"
      :data="data"
    />

    <div class="col-start-7 col-start-8 hidden" />
  </div>
</template>

<style scoped lang="postcss">
.image {
  /* Since subgrid is only in col direction, we need to manually set row-direction gap */
  row-gap: inherit;
}

.image img {
  min-height: 0;
  width: 100%;
  object-fit: cover;
  place-self: stretch;
}

.image:not(.image--frame) img {
  height: 100%;
}

.image > :not(img, figure) {
  min-height: max-content;
  height: max-content;
}

.image--frame {
  @apply py-[var(--grid-col-gap)];

  &.image--left {
    @apply md:place-content-center lg:justify-end;
  }

  &.image--right {
    @apply md:place-content-center lg:justify-start;
  }
}

.image--frame img {
  max-width: 100%;
  max-height: min(80vh, 100%);
  width: auto;
}
</style>
