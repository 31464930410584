import {
  object,
  string,
  number,
  boolean,
  array,
  nullable,
  optional,
  picklist,
  never,
  intersect,
  record,
} from "valibot"

import {
  HtmlContentSchema,
  ImageAssetSchema,
  BaseItemSchema,
} from "./common.schema"
import { VideoSchema, QuizSchema, AudioSchema } from "./media.schema"
import { BaseRelationSchema } from "./relations.schema"
import { MathTaskSchema } from "./mathTask.schema"

export const ArticleSchema = intersect([
  BaseItemSchema,
  object({
    template: string(),
    titleHtml: optional(string()),
    img: optional(ImageAssetSchema),
    imageCaption: optional(object({ html: string() })),
    imageSize: optional(picklist(["Large", "Medium", "Small", ""])),
    intro: optional(HtmlContentSchema),
    body: optional(HtmlContentSchema),
    bg: optional(string()),
    color: optional(string()),
    hasPictureFrame: optional(boolean()),
    pictureColor: optional(string()),
    pictureText: optional(HtmlContentSchema),
    type: optional(string()),
    frontendHref: optional(string()),
    colorTag: optional(string()),
    hidden: optional(boolean()),
    subjectPageTitlePosition: optional(string()),
    mathTask: optional(MathTaskSchema),
    relations: optional(array(BaseRelationSchema)),
    isDark: optional(boolean()),
    restricted: optional(boolean()),
    audio: optional(AudioSchema),
    video: optional(VideoSchema),
    quiz: optional(QuizSchema),
    parentLocation: optional(
      object({
        content: object({
          _name: string(),
        }),
        priority: number(),
      }),
    ),
  }),
])

export const ProductVariantsSchema = intersect([
  BaseItemSchema,
  object({
    ibexaTitle: string(),
    titleHtml: nullable(string()),
    ean: nullable(string()),
    pensumFor: string(),
    institute: array(string()),
    status: picklist([
      "in-development",
      "coming-soon",
      "partial-release",
      "full-release",
    ]),
    _location: object({
      id: number(),
      pathString: string(),
    }),
    isFree: boolean(),
  }),
])

export const ContentProductPagesSchema = intersect([
  BaseItemSchema,
  object({
    header: object({
      html5: string(),
    }),
    quote: string(),
    groupSalesPitch: object({
      html5: string(),
    }),
    theme: string(),
    blocks: array(never()), // Should be mapped higher up in the stack
    uspList: array(string()),
    productGroupStatusDetails: string(),
  }),
])

export const ContentProductPageBlocksSchema = intersect([
  BaseItemSchema,
  object({
    body: string(),
    header: string(),
    imageText: string(),
    image: nullable(ImageAssetSchema),
    blockOrientation: string(),
  }),
])

export const ContentProductPageUspSchema = intersect([
  BaseItemSchema,
  object({
    uspList: array(string()),
  }),
])

export const ContentProductPageMediaTypesSchema = intersect([
  BaseItemSchema,
  object({
    productMediaTypes: array(
      object({
        text: picklist([
          "Les korte tekster",
          "Lær med video",
          "Løs oppgaver",
          "Test deg selv",
          "Lytt til innholdet",
        ]),
        icon: picklist([
          "align-left",
          "video",
          "puzzle",
          "medal",
          "headphones",
        ]),
      }),
    ),
  }),
])

export const FolderSchema = intersect([
  BaseItemSchema,
  object({
    id: number(),
    titleHtml: optional(string()),
    colorTag: string(),
    img: optional(ImageAssetSchema),
    hidden: boolean(),
    type: string(),
  }),
])

export const ChapterSchema = intersect([
  BaseItemSchema,
  object({
    id: number(),
    hidden: boolean(),
    subjectPageTitlePosition: optional(string()),
    introPages: optional(
      array(
        object({
          image: optional(ImageAssetSchema),
          video: optional(VideoSchema),
        }),
      ),
    ),
    colorTag: optional(string()),
    chapterType: optional(
      picklist([
        "Kun fargefyll",
        "Fargefyll med illustrasjon (.png)",
        "Heldekkende bilde",
      ]),
    ),
    rawHref: string(),
    href: string(),
    frontendHref: optional(
      object({
        path: string(),
        query: record(string(), number()),
      }),
    ),
    img: optional(ImageAssetSchema),
    totalChildren: number(),
    type: string(),
    introPagesContentIds: optional(array(number())),
    isFree: boolean(),
  }),
])

export const ChapterPageSchema = intersect([
  BaseItemSchema,
  object({
    id: number(),
    template: string(),
    richtext: optional(HtmlContentSchema),
    colorTheme: string(),
    image: optional(ImageAssetSchema),
    video: optional(VideoSchema),
  }),
])

export const MarketingArticleSectionSchema = intersect([
  BaseItemSchema,
  object({
    id: string(),
    sectionBody: object({
      html5: string(),
    }),
    sectionBodyIsAQuote: boolean(),
    sectionTitle: string(),
    buttonText: string(),
    buttonUrl: string(),
    image: optional(ImageAssetSchema),
  }),
])

export const MarketingArticleSchema = intersect([
  BaseItemSchema,
  object({
    title: string(),
    subtitle: object({
      html5: string(),
    }),
    tags: array(string()),
  }),
])

export const PageSectionSchema = intersect([
  BaseItemSchema,
  object({
    id: number(),
    title: string(),
    priority: number(),
    image: optional(
      object({
        src: optional(string()),
        alt: optional(string()),
      }),
    ),
    video: optional(VideoSchema),
    richtext: string(),
  }),
])

export const PageSchema = intersect([
  BaseItemSchema,
  object({
    id: number(),
    urlAliases: array(
      object({
        path: string(),
      }),
    ),
    route: object({
      path: string(),
      name: string(),
    }),
    title: string(),
    subtitle: string(),
    metaImage: optional(ImageAssetSchema),
    metaTitle: string(),
    metaDescription: string(),
    layout: string(),
  }),
])
