import {
  array,
  boolean,
  date,
  intersect,
  nullable,
  number,
  object,
  optional,
  string,
  union,
} from "valibot"
import { ImageAssetSchema } from "./common.schema"

const BaseSidenoteSchema = object({
  identifier: string(),
  title: string(),
  html: nullable(string()),
  resourceUrl: string(),
  image: optional(ImageAssetSchema),
  imageCaption: optional(string()),
  video: optional(array(number())),
  appendText: optional(string()),
})

export const SourceSidenoteSchema = intersect([
  BaseSidenoteSchema,
  object({
    identifier: string(),
    resource: object({
      type: string(),
      author: string(),
      title: string(),
      edition: optional(string()),
      publisher: optional(string()),
      publishYear: optional(number()),
      publishDate: optional(nullable(date())),
      url: optional(string()),
    }),
  }),
])

export const TermSidenoteSchema = intersect([
  BaseSidenoteSchema,
  object({
    identifier: string(),
    resource: object({
      definition: string(),
      explanation: string(),
      isFlashcard: boolean(),
    }),
  }),
])

export const PersonSidenoteSchema = intersect([
  BaseSidenoteSchema,
  object({
    identifier: string(),
    resource: object({
      birthYear: number(),
      deceasedYear: optional(number()),
      about: string(),
    }),
  }),
])

export const ExplainerSidenoteSchema = intersect([
  BaseSidenoteSchema,
  object({
    identifier: string(),
    html: string(),
  }),
])

export const WhatIsSidenoteSchema = intersect([
  BaseSidenoteSchema,
  object({
    identifier: string(),
    resource: object({
      explanation: string(),
    }),
  }),
])

export const LegalSourceSidenoteSchema = intersect([
  BaseSidenoteSchema,
  object({
    identifier: string(),
    resource: object({
      title: string(),
      type: string(),
      typeDescription: optional(string()),
      text: string(),
      paragraphAndSection: optional(string()),
      url: optional(string()),
      urlTitle: optional(string()),
    }),
  }),
])

export const SidenoteSchema = union([
  SourceSidenoteSchema,
  TermSidenoteSchema,
  PersonSidenoteSchema,
  ExplainerSidenoteSchema,
  WhatIsSidenoteSchema,
  LegalSourceSidenoteSchema,
])
