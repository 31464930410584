import type { Article, Relation } from "~/types/article"

import createMathTask from "./create-mathtask"
import brightnessFormat from "~/utilities/brightness-format"
import { createHtmlFormatter } from "~/utilities/dom-parsing"
import {
  onlyChar,
  capitalizeChar,
  camalize,
} from "~/utilities/composables"
import { Video, Audio, Quiz, Caption } from "~/types/media"
import { ContentType } from "~/models/Content/ContentType"
import {
  ImageAssetSchema,
  MathTaskIbexa,
} from "~/models/Content/schemas"
import { BaseItem } from "~/models/Content/BaseItem"
import { is } from "valibot"

/**
 *
 * @param node
 * @returns
 */
export function formatData(article: Article): Article {
  const { id, template, bg, color, relatedItems } = article

  const video = relatedItems?.find(
    r => r.contentTypeIdentifier === "video",
  ) as Video | undefined
  const audio = relatedItems?.find(
    r => r.contentTypeIdentifier === "audio",
  ) as Audio | undefined
  const quiz = relatedItems?.find(
    r => r.contentTypeIdentifier === "quiz",
  ) as Quiz | undefined

  if (video) {
    video.captions = video.relatedItems?.filter(
      r => r.contentTypeIdentifier === "caption",
    ) as Caption[]
  }

  const articleSidenotes = relatedItems.filter(isRelation)

  const mathRelations =
    article.children
      ?.filter(child => child.contentTypeIdentifier === "math_task")
      ?.flatMap(m => m.relatedItems ?? [])
      ?.filter(isRelation) ?? []

  const sidenotes = [...articleSidenotes, ...mathRelations].map(
    enrichSidenoteWithMedia,
  )

  const images = relatedItems.filter(
    it => it.contentTypeIdentifier === ContentType.Image,
  )

  const mappedArticleImage = (): Article["img"] => {
    if (!article.img) return undefined

    const articleImage = relatedItems.find(
      r => r.contentId === article.img?.id,
    )

    if (articleImage && is(ImageAssetSchema, articleImage)) {
      return {
        ...article.img,
        detailedDescription: articleImage.detailedDescription,
      }
    } else {
      return article.img
    }
  }

  /** Function to format links and references. This also mutates relations! */
  const formatHtml = createHtmlFormatter(sidenotes, images)

  const mathTasks = article.children?.filter(
    c => c.contentTypeIdentifier === ContentType.MathTaskIbexa,
  ) as MathTaskIbexa[]

  const mathTask = createMathTask(id, mathTasks, formatHtml)

  const intro = {
    html: formatHtml(article.intro?.html ?? ""),
    plaintext: article.intro?.plaintext,
  }

  const body = {
    html: formatHtml(article.body?.html ?? ""),
    plaintext: article.body?.plaintext,
  }

  return {
    ...article,
    intro,
    body,
    // Derived fields
    img: mappedArticleImage(),
    template:
      "U" + onlyChar(capitalizeChar(camalize(template))) + "Article",

    bg: bg === "default" ? "paper" : bg,
    color: color === "default" ? "black" : color,
    isDark: bg ? brightnessFormat(bg) : false,
    relations: sidenotes,
    video,
    audio,
    quiz,
    mathTask,

    // Required missing properties
    colorTag: undefined,
    hidden: false,
    restricted: false,
    frontendHref: undefined,
    subjectPageTitlePosition: undefined,
  }
}

function isRelation(r: BaseItem): r is Relation {
  return (
    r.contentTypeIdentifier === ContentType.SidenoteWhatIs ||
    r.contentTypeIdentifier === ContentType.SidenoteSource ||
    r.contentTypeIdentifier === ContentType.SidenoteLegalSource ||
    r.contentTypeIdentifier === ContentType.SidenotePerson ||
    r.contentTypeIdentifier === ContentType.SidenoteExplainer ||
    r.contentTypeIdentifier === ContentType.SidenoteTerm ||
    r.contentTypeIdentifier === ContentType.Sidenote
  )
}

function enrichSidenoteWithMedia(sidenote: Relation): Relation {
  const video = sidenote.relatedItems?.find(
    item => item.contentTypeIdentifier === "video",
  ) as Video | undefined

  const imageItem = sidenote.relatedItems?.find(
    item => item.contentTypeIdentifier === "image",
  )

  const image =
    imageItem && is(ImageAssetSchema, imageItem)
      ? {
          uri: imageItem.src,
          height: imageItem.height,
          width: imageItem.width,
          alternativeText: imageItem.alt,
          variation: { uri: imageItem.src },
          detailedDescription: imageItem.detailedDescription,
        }
      : undefined

  return {
    ...sidenote,
    video: video ?? null,
    image,
  }
}
