<script setup lang="ts">
import type { Subject } from "~/types/subject"
import UContentFreeLabel from "~/components/Navigation/UContentFreeLabel.vue"
import { useChapterCard } from "~/composables/useChapterCard"

const props = defineProps({
  chapter: {
    type: Object as PropType<Subject>,
    required: true,
  },
})

const chapterCard = useChapterCard({
  chapter: props.chapter,
})

const textColorClass = computed(() =>
  chapterCard.imageType.value === "backgroundImage"
    ? "text-white"
    : chapterCard.colorScheme.bwText,
)
</script>

<template>
  <div class="lg:w-[45%] space-y-4" :class="[textColorClass]">
    <UContentFreeLabel v-if="chapter.showFreeLabel" />
    <div>
      <h3
        class="mb-1 leading-10 line-clamp-2 font-national2compressed lg:text-desktop-chapter-preview-h1 text-mobile-chaper-preview-h1"
      >
        {{ chapter.priority + ". " + chapter.title }}
      </h3>
      <u-media-types
        :data="chapter"
        :background-image-class="textColorClass"
      />
    </div>
  </div>
</template>
