import type { Criterion, Query } from "~/models/Search"
import { formatSearchResults } from "~/mapping/formatSearchResults"
import { SearchResult } from "~/models/Content/SearchResult"
import { buildQuery } from "~/utilities/search-query"
import * as Sentry from "@sentry/vue"

export async function fetchResults(
  query: Query,
  criterion: Criterion = {},
  limit: number = 10,
  offset: number = 0,
  depth: number = 0,
  maxDepth: number = 2,
): Promise<SearchResult> {
  const queryString = buildQuery(query, criterion, limit, offset)

  const response = await api.ibexaApi.get(`/search?${queryString}`)

  const results = formatSearchResults(response.data, queryString)

  const relationsIds = results.items
    .map(item => item.relationIds)
    .flat()

  if (!relationsIds.length || depth >= maxDepth) {
    return results
  }

  try {
    results.relations = await fetchResults(
      {},
      { contentIdCriterion: relationsIds },
      100,
      0,
      depth + 1,
      maxDepth,
    )
    return results
  } catch (error) {
    Sentry.captureException(error)
    results.relations = null
    return results
  }
}
