<script setup lang="ts">
import { RouteLocationRaw } from "vue-router/auto"

const props = defineProps({
  to: {
    type: [String, Object] as PropType<string | RouteLocationRaw>,
    default: "",
  },
  href: { type: String, default: "" },
  disabled: { type: Boolean, default: false },
  stacked: { type: Boolean, default: false },
  pill: { type: Boolean, default: false },
  circle: { type: Boolean, default: false },
  active: { type: Boolean, default: false },
  bold: { type: Boolean, default: false },
  scale: { type: Boolean, default: false },

  theme: {
    type: String as PropType<keyof (typeof buttonConfig)["theme"]>,
    default: "dark",
  },

  size: {
    type: String as PropType<keyof (typeof buttonConfig)["size"]>,
    default: "md",
  },

  variant: {
    type: String as PropType<keyof (typeof buttonConfig)["variant"]>,
    default: "outline",
  },
})

const attributes = computed(() => {
  if (props.to) return { to: props.to }
  if (props.href) return { href: props.href }
})

const emit = defineEmits(["click", "update:active"])
function onClick(event: Event) {
  if (props.disabled) return event.preventDefault()

  emit("click", event)
  emit("update:active", !props.active)
}

const buttonConfig = {
  size: {
    sm: "px-4 pb-2 pt-1.5 text-xl leading-6",
    md: "px-5 pb-2.5 pt-1.5 text-2xl leading-7",
    lg: "px-6 pb-3 pt-2 text-2xl leading-[30px]",
  },
  theme: {
    white:
      "text-white bg-platform-paper border-white hover:border-[--u-contrast]",
    black:
      "text-black bg-black border-black hover:border-[--u-contrast]",
    cta: "text-[--theme-medium] bg-[--theme-medium] border-[--theme-medium] hover:border-[--theme-medium] hover:bg-dark font-medium",
    dark: "text-dark bg-darker border-dark hover:border-darker hover:bg-lighter",
    light:
      "text-[--theme-lightest] bg-lightest border-lightest hover:border-lightest hover:bg-lighter",
    medium:
      "text-dark bg-[--theme-medium] border-dark hover:border-dark hover:bg-dark",
    "translucent-light":
      "bg-[--theme-translucent-light] text-lightest border-lightest hover:border-darkest hover:bg-darkest",

    success:
      " text-green-700 bg-green-700 border-green-700 hover:bg-green-800",
  },
  variant: {
    outline: `border-2 !bg-transparent  ${
      props.theme === "light"
        ? "hover:!bg-darker hover:!border-lighter"
        : props.theme === "dark"
          ? "hover:!bg-lighter hover:!border-medium"
          : "hover:!bg-lightest hover:!border-dark"
    }`,
    quite: "!border-transparent hover:underline !bg-transparent",
    filled: `${
      props.theme === "white"
        ? "!text-darkest !border-darkest hover:!bg-lighter hover:!border-light"
        : props.theme === "light"
          ? "!text-darkest"
          : props.theme === "medium" ||
              props.theme === "dark" ||
              props.theme === "cta" ||
              props.theme === "success" ||
              props.theme === "error"
            ? "!text-white"
            : ""
    } ${props.theme !== "white" ? "!border-transparent" : ""}`,
  },

  //Boolean Button States

  disabled:
    "pointer-events-none border-none bg-slate-200 !text-gray-500",
  pill: "!rounded-full",
  bold: "font-medium",
  stacked: {
    button: "flex-col border-none",
    icon: "border-1 border-solid border-black",
  },
  circle: {
    sm: "!p-2.5 !rounded-full min-w-6 h-fit",
    md: "!p-3 !rounded-full min-w-6 h-fit",
    lg: "!p-3 !rounded-full min-w-6 h-fit",
  },
  scale: "md:scale-100 scale-75",
}
</script>

<template>
  <component
    :is="`${to ? 'router-link' : href ? 'a' : 'button'}`"
    :aria-disabled="disabled ? 'true' : null"
    :="attributes"
    class="flex w-fit cursor-pointer select-none items-center justify-center space-x-2 truncate rounded-md border-2 p-2.5 font-national2 focus-visible:ring-[3px] focus-visible:ring-light focus-visible:ring-offset-2"
    :class="[
      buttonConfig.size[size],
      buttonConfig.theme[theme],
      active
        ? buttonConfig.variant.filled
        : buttonConfig.variant[variant],
      {
        [buttonConfig.disabled]: disabled,
        [buttonConfig.pill]: pill,
        [buttonConfig.circle[size]]: circle,
        [buttonConfig.bold]: bold,
        [buttonConfig.stacked.button]: stacked,
        [buttonConfig.scale]: scale,
        '!px-0': variant === 'quite' && !circle,
      },
    ]"
    @click="!to && !href ? onClick($event) : undefined"
  >
    <slot name="prepend" />
    <div v-if="$slots.default" class="truncate">
      <slot />
    </div>
    <slot name="append" />
  </component>
</template>
