import {
  ProductsMap,
  ProductVariants,
} from "~/models/Content/ContentProducts"
import { ProductPageList } from "~/api/getProductPageList"
import {
  formatList,
  parseInstitutionNameAndCode,
} from "../../src/utilities/format"
import { formatForURL } from "../../src/utilities/composables"
import {
  buildProductKey,
  extractProductVariant,
} from "../../src/utilities/format-product"
import { ProductStatus } from "~/types/productData.d"

const isProductStatusReleased = (status: ProductStatus): boolean =>
  status === "full-release" || status === "partial-release"

export const getGroupedProductVariants = (
  rawProductVariants: ProductVariants[],
  marketingData: ProductPageList | null,
): ProductsMap | undefined => {
  const productVariants: ProductsMap = {}
  for (const productVariant of rawProductVariants) {
    const fullProductTitle = productVariant.ibexaTitle
    const institutionCode = extractProductVariant(fullProductTitle)

    if (!institutionCode) continue

    const productTitle = fullProductTitle.replace(
      ` ${institutionCode}`,
      "",
    )

    const productKey = buildProductKey(productVariant.ibexaTitle)

    const parsedCurriculumInfo = parseInstitutionNameAndCode(
      productVariant.pensumFor,
    )

    // institute is for examle "Universitetet i Oslo (UiO)" we want to extract the short name
    // and will return "UiO"
    const institutionAcronyms = formatList(
      productVariant.institute.map(i => {
        const startIndex = i.indexOf("(")
        const endIndex = i.indexOf(")")
        if (startIndex !== -1 && endIndex !== -1) {
          return i.substring(startIndex + 1, endIndex)
        }
        return i
      }),
    )

    if (!productVariants[productKey]) {
      productVariants[productKey] = {
        productTitle,
        productTitleHtml: productVariant.titleHtml,
        urlFormatProductTitle: formatForURL(productTitle),
        hasAtLeastOneReleasedProduct: isProductStatusReleased(
          productVariant.status,
        ),
        productGroupStatus: [
          {
            locationId: productVariant._location.id,
            status: productVariant.status,
          },
        ],
        theme: marketingData?.[productKey]?.theme,
        header: marketingData?.[productKey]?.header?.html5,
        quote: marketingData?.[productKey]?.quote,
        groupSalesPitch:
          marketingData?.[productKey]?.groupSalesPitch?.html5,
        productGroupStatusDetails:
          marketingData?.[productKey]?.productGroupStatusDetails,
        productCurriculumFor:
          parsedCurriculumInfo.shortNamesAndCourseCodes,
        vanitySentence: parsedCurriculumInfo.vanitySentence,
        variants: [
          {
            name: fullProductTitle,
            shortTitle: productVariant.shortTitle,
            titleHtml: productVariant.titleHtml,
            title: productVariant.title,
            ean: productVariant.ean,
            curriculumFor: institutionAcronyms,
            isReleased: isProductStatusReleased(
              productVariant.status,
            ),
            status: productVariant.status,
            variantKey: institutionCode.toLowerCase(),
            shortVariantLabel: institutionCode,
            locationId: productVariant._location.id,
            pathString: productVariant._location.pathString,
            isFree: productVariant.isFree,
          },
        ],
      }
    } else {
      productVariants[productKey].productCurriculumFor = [
        ...productVariants[productKey].productCurriculumFor,
        ...parsedCurriculumInfo.shortNamesAndCourseCodes,
      ]
      productVariants[productKey].productGroupStatus.push({
        locationId: productVariant._location.id,
        status: productVariant.status,
      })
      productVariants[productKey].vanitySentence =
        parsedCurriculumInfo.vanitySentence
      productVariants[productKey].variants.push({
        name: fullProductTitle,
        shortTitle: productVariant.shortTitle,
        titleHtml: productVariant.titleHtml,
        title: productVariant.title,
        ean: productVariant.ean,
        curriculumFor: institutionAcronyms,
        isReleased: isProductStatusReleased(productVariant.status),
        status: productVariant.status,
        variantKey: institutionCode.toLowerCase(),
        shortVariantLabel: institutionCode,
        locationId: productVariant._location.id,
        pathString: productVariant._location.pathString,
        isFree: productVariant.isFree,
      })
    }
    if (
      productVariant.titleHtml &&
      !productVariants[productKey].productTitleHtml
    ) {
      productVariants[productKey].productTitleHtml =
        productVariant.titleHtml
    }
    if (
      productVariants[productKey] &&
      !productVariants[productKey].hasAtLeastOneReleasedProduct &&
      isProductStatusReleased(productVariant.status)
    ) {
      productVariants[productKey].hasAtLeastOneReleasedProduct = true
    }
  }

  return Object.keys(productVariants).length > 0
    ? productVariants
    : undefined
}
