<script setup lang="ts">
const props = defineProps({
  small: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  variant: {
    type: String as PropType<"filled" | "outline">,
    default: "filled",
  },
})

const styles = {
  variant: {
    filled: "bg-[--theme-medium] text-white",
    outline:
      "bg-transparent border-[--theme-medium] border-2 text-[--theme-medium]",
  },
}
</script>

<template>
  <span
    class="h-fit rounded px-[6px] py-[2px] font-national2 font-bold italic !leading-5"
    :class="[
      styles.variant[props.variant],
      {
        'text-sm lg:text-base': props.small,
        'text-lg lg:text-xl': !props.small,
      },
    ]"
  >
    <slot />
  </span>
</template>
