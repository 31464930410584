<script setup lang="ts">
import { SidenoteData } from "~/components/Sidenote/USidenoteContainer.vue"
import { toDisplayLanguage } from "../../utilities/videoUtils"

const props = defineProps({
  note: {
    type: Object as PropType<SidenoteData>,
    required: true,
  },
  titleClass: {
    type: String as PropType<string>,
    default: "",
    required: false,
  },
})

const baseUri = import.meta.env.VITE_IBEXA_BASEURL

const sidenoteHeader = (
  sidenoteType: SidenoteData["identifier"],
): { label: string; title?: string } => {
  const title = props.note.title

  switch (sidenoteType) {
    case "source":
      return { label: "Kilde", title }
    case "term":
      return { label: "Begrep", title }
    case "legal_source":
      return { label: "Rettskilde", title }
    case "what_is":
      return { label: "Hva er?", title }
    case "person":
      return { label: "Person", title }
    case "solution":
      return { label: "Fasit", title: undefined }
    default:
      return { label: "Forklaring", title }
  }
}
</script>

<template>
  <div
    class="flex w-screen flex-col place-content-center gap-8 place-self-stretch break-words px-6 py-24 md:w-[--sidebar-width] md:px-12 md:py-24"
  >
    <header class="grid gap-2">
      <small class="text-lg font-national2">
        <b>{{ sidenoteHeader(note.identifier).label }}</b>
        <slot name="sidenoteType" />
      </small>
      <h2
        v-if="sidenoteHeader(note.identifier).title"
        :class="`font-numeraWebRegular text-4xl <md:break-all ${titleClass}`"
      >
        {{ sidenoteHeader(note.identifier).title }}
      </h2>
    </header>
    <slot name="tag" />
    <slot name="content" />
    <slot name="url" />

    <div class="text-xl font-national2" v-html="note.html" />

    <template v-if="note.image">
      <figure class="place-self-start">
        <img
          v-if="note.image"
          :src="note.image.variation?.uri || note.image.uri"
          :width="note.image.width"
          :height="note.image.height"
          :alt="note.image.alternativeText"
          :aria-describedby="
            note.image?.detailedDescription
              ? 'detailed-sidenote-image-description'
              : undefined
          "
          class="h-[164px] w-auto"
        />
        <URichText
          v-if="note.image?.detailedDescription"
          id="detailed-sidenote-image-description"
          class="sr-only"
          :html="note.image?.detailedDescription"
        />

        <figcaption
          v-if="note.imageCaption"
          class="mt-4"
          v-text="note.imageCaption"
        />
      </figure>
    </template>
    <template v-if="note.video">
      <video
        v-if="note.video?.metadata"
        :src="note.video.metadata.elementURI"
        controlslist="nodownload"
        controls
        preload="auto"
        :captions="note.video.captions"
        :poster="note.video.img?.src || note.video?.metadata?.image"
      >
        <track
          v-for="caption in note.video.captions"
          :key="caption.captionFile.uri"
          :src="baseUri + caption.captionFile.uri"
          :srclang="caption.language"
          :label="toDisplayLanguage(caption.language)"
        />
      </video>
    </template>
  </div>
</template>
