<script setup lang="ts">
const backgroundColor = useBackgroundColor()
backgroundColor.value = "var(--theme-lightest)"
const products = useProductVariantsStore()
</script>

<template>
  <div>
    <UHeader
      :product="products.activeProduct"
      class="px-8 lg:px-40"
    />
    <main class="flex items-center justify-center w-full lg:mt-10">
      <div class="grow">
        <router-view />
      </div>
    </main>
  </div>
  <UMarketingFooter />
</template>
